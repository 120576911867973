import IntroImageGucciVisions from '@/assets/img/intro/gv.png'
import IntroImageBoutiqueBookstore from '@/assets/img/intro/bb.png'
import IntroImageGucciGiardino25 from '@/assets/img/intro/g25.png'
import IntroImageGucciOsteria from '@/assets/img/intro/go.png'
import MenuPreviewImageGucciVisions from '@/assets/img/menu/gv.jpg'
import MenuPreviewImageBoutiqueBookstore from '@/assets/img/menu/bb2.jpg'
import MenuPreviewImageGucciGiardino25 from '@/assets/img/menu/g25.jpg'
import MenuPreviewImageGucciOsteria from '@/assets/img/menu/go2.jpg'

export const primaryItems = [
  {
    id: 0,
    order: {
      desktop: 1,
      mobile: 3
    },
    enTitle: 'Gucci Visions',
    itTitle: 'Gucci Visions',
    frTitle: 'Gucci Visions',
    jaTitle: 'Gucci Visions',
    zhTitle: '《古驰视界》',
    to: {
      name: 'Editorial',
      params: {
        pageSlug: 'gucci-visions'
      }
    },
    workingHours: {
      enText: 'Every day from 10am to 7pm\\\nwith last entry at 6:30pm',
      itText: 'Tutti i giorni dalle ore 10:00 alle 19:00,\\\ncon ultimo ingresso galleria alle ore 18:30.',
      frText: 'Tous les jours de 10 h à 19 h\\\n(dernières entrées à 18 h 30)',
      jaText: '10:00am-7:00pm\\\n最終入場6:30pmまで',
      zhText: '每日上午10点至晚上7点\\\n（6点30分后停止入场）'
    },
    address: {
      enText: '**ADDRESS**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nT: +39 055.75927010',
      itText: '**DOVE SIAMO**\n\nPiazza della Signoria 10, Firenze, 50125, Italia\\\nT: +39 055.75927010',
      frText: '**ADRESSE**\n\nPiazza della Signoria 10, Florence, 50125, Italie\\\nTél: +39 055 75927010',
      jaText: '**住所**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nTEL: +39 055.75927010',
      zhText: '**地址**\n\n意大利佛罗伦萨市政广场10号，邮编50125\\\n电话：+39 055.75927010'
    },
    intro: {
      top: 28,
      left: 45,
      width: 50,
      height: 25,
      image: IntroImageGucciVisions
    },
    previewImage: MenuPreviewImageGucciVisions
  },
  {
    id: 1,
    order: {
      desktop: 2,
      mobile: 4
    },
    enTitle: 'The Boutique and Bookstore',
    itTitle: 'La Boutique e il Bookstore',
    frTitle: 'La boutique et la librairie',
    jaTitle: 'ショップ＆ブックストア',
    zhTitle: '精品店及书店',
    to: {
      name: 'Editorial',
      params: {
        pageSlug: 'boutique-bookstore'
      }
    },
    workingHours: {
      enText: '**HOURS**\n\nEvery day from 10am to 8pm',
      itText: '**ORARI**\n\nTutti i giorni dalle ore 10:00 alle 20:00',
      frText: '**HORAIRES**\n\nTous les jours de 10h à 20h',
      jaText: '**営業時間**\n\n10:00am-8:00pm',
      zhText: '**时间**\n\n每日上午10点至晚上8点'
    },
    address: {
      enText: '**ADDRESS**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nT: +39 055.75927010',
      itText: '**DOVE SIAMO**\n\nPiazza della Signoria 10, Firenze, 50125, Italia\\\nT: +39 055.75927010',
      frText: '**ADRESSE**\n\nPiazza della Signoria 10, Florence, 50125, Italie\\\nTél: +39 055 75927010',
      jaText: '**住所**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nTEL: +39 055.75927010',
      zhText: '**地址**\n\n意大利佛罗伦萨市政广场10号，邮编50125\\\n电话：+39 055.75927010'
    },
    intro: {
      top: 58,
      left: 48,
      width: 46,
      height: 28,
      image: IntroImageBoutiqueBookstore
    },
    previewImage: MenuPreviewImageBoutiqueBookstore
  },
  {
    id: 2,
    order: {
      desktop: 5,
      mobile: 1
    },
    enTitle: 'Gucci Giardino',
    itTitle: 'Gucci Giardino',
    frTitle: 'Gucci Giardino',
    jaTitle: 'グッチ ジャルディーノ',
    zhTitle: 'Gucci Giardino',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino',
      it: 'https://www.gucciosteria.com/it/florence/giardino'
    },
    workingHours: {
      enText: '**HOURS**\n\nMonday to Sunday: 10am – 12am\\\nBreakfast: 10am – 3pm\\\nLight Lunch: 10am – 4pm\\\nLight Dinner: 6pm – 9pm\\\nAfternoon Tea: 3pm – 6pm\\\nAperitif & Cocktails: 6pm - 12am\\\nCocktails are served from 12pm - 12am\\\nClosing days: January 1, August 15, December 25',
      itText: '**ORARI**\n\nDal lunedì alla domenica: 10:00 – 00:00\\\nColazione: 10:00 – 15:00\\\nPranzo: 10:00 – 16:00\\\nCena: 18:00 – 21:00\\\nTè del pomeriggio: 15:00 – 18:00\\\nAperitivo e cocktail: 18:00 – 00:00\\\nI cocktail vengono serviti dalle 12:00 - 00:00\\\nGiorni di chiusura: 1 gennaio, 15 agosto, 25 dicembre',
      frText: '**HORAIRES**\n\nDu lundi au dimanche: 10h – 0h\\\nPetit-déjeuner: 10h – 16h\\\nDéjeuner/Dîner: 18 h – 21 h\\\nThé: 15 h – 18 h\\\nApéritif et cocktails: 18 h – 0 h\\\nLes cocktails sont servis de 12 h à 0 h\\\nJours de fermeture: 1er janvier, 15 août, 25 décembre',
      jaText: '**営業時間**\n\n月曜日-日曜日 10:00-00:00\\\n朝食 10:00-16:00\\\nブランチ 18:00-21:00\\\nアフタヌーンティー 15:00-18:00\\\nアペリティフ＆カクテル 18:00-0:00\\\nカクテルは12:00-00:00まで\\\n1月1日、8月15日、12月00日は休業',
      zhText: '**时间**\n\n周一至周日：上午10点至次日凌晨0点\\\n早餐：上午10点至16点\\\n简易午餐：上午18点至下午21点\\\n下午茶：下午3点至6点\\\n餐前酒和鸡尾酒：下午6点至次日凌晨0点\\\n鸡尾酒在中午12点至次日凌晨0点供应\\\n闭馆日：1月1日、8月15日、12月25日'
    },
    address: {
      enText: '**ADDRESS**\n\nPiazza della Signoria 37r, Florence, 50125, Italy\\\nT: +39 055 75927012\\\nEmail: [giardino.25@gucci.com](mailto:giardino.25@gucci.com)',
      itText: '**DOVE SIAMO**\n\nPiazza della Signoria 37r, Firenze, 50125, Italia\\\nT: +39 055 75927012\\\nEmail: [giardino.25@gucci.com](mailto:giardino.25@gucci.com)',
      frText: '**ADRESSE**\n\nPiazza della Signoria 37r, Florence, 50125, Italie\\\nTél: +39 055 75927012\\\nEmail: [giardino.25@gucci.com](mailto:giardino.25@gucci.com)',
      jaText: '**住所**\n\nPiazza della Signoria 37r, Florence, 50125, Italy\\\nTEL: +39 055 75927012\\\nEmail: [giardino.25@gucci.com](mailto:giardino.25@gucci.com)',
      zhText: '**地址**\n\n意大利佛罗伦萨市政广场37r号，邮编50125\\\n电话：+39 055 75927012\\\nEmail: [giardino.25@gucci.com](mailto:giardino.25@gucci.com)'
    },
    intro: {
      top: 64,
      left: 0,
      width: 24,
      height: 15,
      image: IntroImageGucciGiardino25
    },
    previewImage: MenuPreviewImageGucciGiardino25
  },
  {
    id: 3,
    order: {
      desktop: 5,
      mobile: 1
    },
    enTitle: 'Gucci Osteria',
    itTitle: 'Gucci Osteria',
    frTitle: 'Gucci Osteria',
    jaTitle: 'グッチ オステリア',
    zhTitle: 'Gucci Osteria',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en',
      it: 'https://www.gucciosteria.com/it'
    },
    workingHours: {
      enText: '**HOURS**\n\nEvery day\\\nFrom 12:30pm – 3pm\\\nFrom 7:30pm – 10pm',
      itText: '**ORARI**\n\nTutti i giorni \\\nDalle 12:30 alle 15:00 \\\nDalle 19:30 alle 22:00',
      frText: '**HORAIRES**\n\nTous les jours\\\nDe 12 h 30 à 15 h\\\nDe 19 h 30 à 22 h',
      jaText: '**営業時間**\n\nランチ 12:30-15:00\\\nディナー 19:30-22:00',
      zhText: '**时间**\n\n每日\\\n中午12点30分至下午3点\\\n晚上7点30分至10点'
    },
    address: {
      enText: '**ADDRESS**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nT: +39 055.75927010',
      itText: '**DOVE SIAMO**\n\nPiazza della Signoria 10, Firenze, 50125, Italia\\\nT: +39 055.75927010',
      frText: '**ADRESSE**\n\nPiazza della Signoria 10, Florence, 50125, Italie\\\nTél: +39 055 75927010',
      jaText: '**住所**\n\nPiazza della Signoria 10, Florence, 50125, Italy\\\nTEL: +39 055.75927010',
      zhText: '**地址**\n\n意大利佛罗伦萨市政广场10号，邮编50125\\\n电话：+39 055.75927010'
    },
    intro: {
      top: 58,
      left: 38,
      width: 10,
      height: 17,
      image: IntroImageGucciOsteria
    },
    previewImage: MenuPreviewImageGucciOsteria
  }
]

export const secondaryItems = [
  {
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/menu/',
      it: 'https://www.gucciosteria.com/it/florence/menu/'
    },
    enLabel: 'Discover the Gucci Osteria Florence Menu',
    itLabel: 'Scopri il Menu di Gucci Osteria Firenze',
    frLabel: 'Découvrez le menu de Gucci Osteria Florence',
    jaLabel: 'グッチ オステリア フィレンツェのメニューを見る',
    zhLabel: '探索佛罗伦萨Gucci Osteria餐厅菜单'
  }
]
