<template>
  <nav class="menu-items">
    <ul>
      <li v-for="(item, index) in items" :key="item.id">
        <component
          :is="item.to ? 'router-link' : 'a'"
          :key="item.id + index"
          :href="item.externalUrl && (item.externalUrl[$language.current] || item.externalUrl.en)"
          :to="item.to ? {
            name: item.to.name,
            params: item.to.params
          } : undefined"
          :class="{ active: index === currentIndex }"
          :target="item.externalUrl ? '_blank' : undefined"
          @click="$emit('item-click', { item, index })"
          @click.native="$emit('item-click', { item, index })"
          @mouseenter="$emit('item-hover', { item, index })"
          @mouseenter.native="$emit('item-hover', { item, index })"
        >
          <span>{{items[index][$language.current + 'Title']}}</span>
        </component>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'MenuItems',
  components: { },
  props: {
    currentIndex: { type: Number, default: 0 },
    items: { type: Array, required: true }
  }
}
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  ul {
    list-style: none;
    padding-left: 0;

    li {
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  a {
    color: $c-black;
    display: inline-block;
    position: relative;

    span {
      padding-right: 70px;
      display: inline-block;
      font-family: 'Nitti Grotesk Normal';
      font-size: 40px;
      line-height: 52px;
      letter-spacing: 0.05em;
      color: $c-black;
      opacity: 0.2;
      transition: opacity 0.5s;

      @include breakpoint ('sm-and-down') {
        font-size: 28px;
        line-height: 40px;
        padding-right: 40px;
      }
    }

    &:hover,
    &.active {
      color: $c-black;

      span {
        opacity: 1;
      }
    }

  }
}
</style>
