<template>
  <div class="menu-container">
    <div ref="menuContainer" class="menu-content-container">
      <div class="gradient"></div>
      <div :class="{ 'gucci-app': $root.isApp }" class="menu-content">
        <div class="top-title">
          <!-- <span>{{$gettext('menuTopLabel')}}</span> -->
        </div>
        <MenuItems
          @item-click="trackMenuItemClick($event)"
          @item-hover="menuItemHover($event)"
          :currentIndex="currentIndex"
          :items="primaryItems"
        />
        <div class="bottom-links-title">
          <!-- <span>{{$gettext('menuBottomLabel')}}</span> -->
        </div>
        <div v-for="(item, index) in secondaryItems" :key="index" class="bottom-links">
          <a
          :href="item.externalUrl && (item.externalUrl[$language.current] || item.externalUrl.en)"
          target="_blank"
          @click="trackMenuItemClick(item)"
          >
            {{item[`${$language.current}Label`] || item.enLabel}}
          </a>
        </div>
        <div class="d-none d-md-block main-room-preview">
          <v-fade-transition mode="out-in">
            <img
              v-if="primaryItems[currentIndex] && primaryItems[currentIndex].previewImage"
              :key="currentIndex"
              :src="primaryItems[currentIndex].previewImage"
              decoding="async"
              draggable="false"
            >
          </v-fade-transition>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { gsap } from 'gsap'

import Footer from '@/organisms/Footer.vue'
import MenuItems from '@/atoms/MenuItems.vue'

export default {
  name: 'Menu',
  components: {
    Footer,
    MenuItems
  },
  data () {
    return {
      currentIndexUpdated: false,
      currentIndex: null
    }
  },
  beforeDestroy () {
    this.enterTl && this.enterTl.kill()
    this.leaveTl && this.leaveTl.kill()
    this.itemHoverTl && this.itemHoverTl.kill()
  },
  async mounted () {
    await this.$nextTick()
    this.createEnterTimeline()
    this.createLeaveTimeline()
    this.createMenuItemTimeline()
    this.preloadMenuImages()
  },
  computed: {
    primaryItems () {
      const items = [...this.$root.settings.menu.primaryItems]
      return items.sort((a, b) => a.desktopOrder - b.desktopOrder)
    },
    secondaryItems () {
      return this.$root.settings.menu.secondaryItems
    }
  },
  methods: {
    async createEnterTimeline () {
      await this.$nextTick()
      this.enterTl = gsap.timeline({
        paused: true,
        onStart: () => {
          this.$events.emit('menu-animation-start')
          this.$refs.menuContainer.style.setProperty('overflow', 'hidden', 'important')
        },
        onComplete: () => {
          !this.$root.menuOpen && this.exit(2)
          this.$refs.menuContainer.style.setProperty('overflow', 'auto', 'important')
        }
      })
      this.enterTl.fromTo(this.$el, { y: '-200%' }, { y: 0, duration: 2, ease: 'expo.out' })
      this.enterTl.fromTo(this.$el.querySelectorAll(['.top-title span', '.menu-items a']), { y: '100%' }, { y: 0, stagger: 0.15, duration: 0.5, ease: 'power4.out' }, '-=1')
      this.enterTl.fromTo(this.$el.querySelectorAll(['.bottom-links-title', '.bottom-links', 'footer']), { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.5, ease: 'power4.out' })
    },
    createLeaveTimeline () {
      this.leaveTl = gsap.timeline({
        paused: true,
        onStart: () => {
          this.$refs.menuContainer.style.setProperty('overflow', 'hidden', 'important')
        },
        onComplete: () => {
          this.$events.emit('menu-animation-end')
          this.currentIndexUpdated = false
          this.currentIndex = null
          this.$root.menuOpen && this.enter()
        }
      })
      this.leaveTl.to(this.$el.querySelectorAll(['.bottom-links-title', '.bottom-links', 'footer']), { autoAlpha: 0, duration: 0.5, ease: 'power4.inOut' })
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.leaveTl.to(this.$el.querySelector('.main-room-preview'), { x: '300%', rotate: 30, duration: 0.5, 'transform-origin': 'top right', ease: 'power4.in' })
      }
      this.leaveTl.to(this.$el.querySelectorAll(['.top-title span', '.menu-items a']), { y: '100%', stagger: 0.2, duration: 0.5, ease: 'power4.in' }, '-=1')
      this.leaveTl.to(this.$el, { y: '-200%', duration: 2, ease: 'expo.in' }, '-=1')
    },
    createMenuItemTimeline () {
      if (this.$vuetify.breakpoint.smAndDown) return
      gsap.set(this.$el.querySelector('.main-room-preview'), { translateX: '300%', rotate: -30, 'transform-origin': 'top left', ease: 'power4.out' })
      this.itemHoverTl = gsap.timeline({ paused: true, onStart: () => { this.currentIndexUpdated = true } })
      this.itemHoverTl.fromTo(
        this.$el.querySelector('.main-room-preview'),
        { translateX: '300%', rotate: -30, 'transform-origin': 'top left', ease: 'power4.out' },
        { x: 0, rotate: 0, duration: 2 }
      )
    },
    menuItemHover ({ room, index }) {
      this.currentIndex = index
      !this.currentIndexUpdated && this.itemHoverTl && this.itemHoverTl.play(0)
    },
    enter (timeScale = 1) {
      if (!this.enterTl || this.enterTl.isActive() || this.leaveTl.isActive()) return
      this.enterTl.timeScale(timeScale)
      this.enterTl.play(0)
    },
    exit (timeScale = 1) {
      if (!this.leaveTl || this.leaveTl.isActive()) return
      if (this.enterTl.isActive()) {
        this.enterTl.timeScale(3)
        return
      }
      this.leaveTl.timeScale(timeScale)
      this.leaveTl.invalidate()
      this.leaveTl.play(0)
    },
    preloadMenuImages () {
      this.primaryItems && this.primaryItems.forEach(r => {
        if (!r.reference || !r.reference.preloadMenuImages) return
        if (r.reference.previewMenuImage.id) axios.get(`/media/${r.reference.previewMenuImage.id}`)
      })
    },
    trackMenuItemClick (item) {
      let eventAction = 'Click on Menu footer cta'
      let eventLabel = ''

      if (item.room && item.room.enTitle) eventLabel = item.room.enTitle
      else if (item.enTitle) eventLabel = item.enTitle
      else if (item.labels) eventLabel = item.labels.find(label => label.lang === 'en').text
      else {
        eventAction = 'Click on Menu footer cta'
        eventLabel = item
      }

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'generic_event',
          eventCategory: 'Menu tendina',
          eventAction,
          eventLabel
        })
      }
    }
  },
  watch: {
    '$root.menuOpen': {
      immediate: true,
      handler (state) {
        document.getElementsByTagName('html')[0].style.setProperty('overflow-y', state ? 'hidden' : 'auto', 'important')
        state ? this.enter() : this.exit(3)
      }
    },
    $route (to, from) {
      if (to.params.language !== from.params.language) return
      this.$root.menuOpen = false
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-menu;
  color: $c-black;

  a {
    color: $c-black;
  }
}

.menu-content-container {
  position: relative;
  background-color: $c-white;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.gradient {
  pointer-events: none;
  user-select: none;
  height: 210px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: linear-gradient(180deg, $c-white 0%, rgba($c-white, 0) 100%);
}

.menu-content {
  height: auto;
  min-height: 100%;
  padding: 100px 130px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include breakpoint ('sm-and-down') {
    padding: 150px 48px 60px;
  }

  &.gucci-app {
    padding-top: 175px;
  }
}

.top-title {
  overflow: hidden;
  margin-bottom: 19px;

  span {
    display: inline-block;
    font-family: 'Nitti Grotesk Normal';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    @include breakpoint ('sm-and-down') {
      font-size: 14px;
    }
  }
}

.bottom-links-title {
  overflow: hidden;
  margin-top: 50px;
  margin-bottom: 22px;

  span {
    display: inline-block;
    font-family: 'Nitti Grotesk Normal';
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
}

.bottom-links {
  overflow: hidden;
  margin-bottom: 12px;

  a {
    display: inline-block;
    font-family: 'Nitti Grotesk Normal';
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0.05em;
    opacity: 0.3;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }
}

.main-room-preview {
  position: fixed;
  top: 50%;
  right: 15%;
  margin-top: 50vh * -0.5;
  width: 50vh;
  height: 50vh;

  @include breakpoint ('md-only') {
    margin-top: 40vh * -0.5;
    width: 40vh;
    height: 40vh;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

footer {
  @include breakpoint ('md-and-up') {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
