<template>
  <v-fade-transition mode="out-in">
    <div v-show="loading" class="loading-container">
      <LogoSvg v-if="false" />
      <h2 class="text-center text-uppercase mt-4">{{ $gettext('loadingLabel') }}</h2>
    </div>
  </v-fade-transition>
</template>

<script>
import LogoSvg from '@/assets/svg/gucci_palazzo_logo.svg'

export default {
  name: 'Loading',
  components: {
    LogoSvg
  },
  data () {
    return { loading: false }
  },
  async mounted () {
    await this.$nextTick()
    this.$events.on('loading-start', () => { this.loading = true })
    this.$events.on('loading-end', () => { this.loading = false })
  }
}
</script>

<style lang="scss" scoped>
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-loading;
  background-color: $c-white;
  color: $c-black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

svg {
  width: 32vw;
}
</style>
