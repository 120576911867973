import Vue from 'vue'
import GetTextPlugin from 'vue-gettext'
import assign from 'lodash/assign'

import availableLanguages from '@/assets/data/availableLanguages.json'

// only one translation, add another one if you have another
import en from '@/assets/data/locale/en/translation.json'
import it from '@/assets/data/locale/it/translation.json'
import fr from '@/assets/data/locale/fr/translation.json'
import ja from '@/assets/data/locale/ja/translation.json'
import zh from '@/assets/data/locale/zh/translation.json'

// usage: https://github.com/Polyconseil/vue-gettext
Vue.use(GetTextPlugin, {
  translations: assign({}, en, it, fr, ja, zh),
  // use this line to join locales together
  // translations: Object.assign({}, it, zh, es, etc),
  availableLanguages: availableLanguages,
  defaultLanguage: 'en',
  silent: true
})
