<template>
  <aside>
    <iframe
      v-if="$root.checkoutID && ($route.name === 'Giardino25' || $route.name === 'ShopifyProduct') || ($route.params.roomSlug && $route.params.roomSlug.includes('osteria'))"
      :src="'https://gucciosteria.com/pixel.html?checkout=' + $root.checkoutID"
      title="description"
      class="hidden-iframe">
    </iframe>
    <div @click="$root.shopifyCartOpen = false" class="cart-background"></div>
    <form @submit.prevent="onCartSubmit()" class="cart-content">
      <div v-if="!!Cart" class="cart-step-first">
        <div class="cart-header">
          <p class="cart">{{ $gettext('cart') }} <span>({{CartLengthLabel}})</span></p>
          <ButtonClose @click="$root.shopifyCartOpen = false" type="button" />
        </div>
        <div class="cart-main">
          <div v-if="Cart.length">
            <div v-for="({node}, index) in Cart" :key="index" class="cart-product">
              <img
                v-if="node.variant.image"
                :key="node.variant.image.id"
                :src="node.variant.image.src"
                :alt="`Variant image ${node.variant.title}`"
                decoding="async"
                draggable="false"
              />
              <div class="product-info">
                <span class="product-title">{{node.title}}</span>
                <span class="product-price">{{formatPrice(node.variant)}}</span>
                <div class="product-actions">
                  <div class="product-quantity">
                    <button @click="cartUpdateItem(node.id, node.quantity - 1)" :disabled="$root.checkoutLoading || node.quantity <= 1" type="button">-</button>
                    <span class="px-2">{{node.quantity}}</span>
                    <button @click="cartUpdateItem(node.id, node.quantity + 1)" :disabled="$root.checkoutLoading || node.quantity >= 10" type="button">+</button>
                  </div>
                  <button @click="cartRemoveItem(node.id)" :disabled="$root.checkoutLoading" type="button" class="product-remove">
                    {{ $gettext('remove') }}
                  </button>
                </div>
              </div>
            </div>
            <MarkdownBlock
              :text="$gettext('newProductsDisclaimer')"
              tag="p"
              class="cart-new-products-discalimer"
              inline
              linkify
            />
            <div class="cart-total-price">
              <span>{{ $gettext('total') }}</span>
              <span>{{CartTotalPrice}}</span>
            </div>
          </div>
          <div v-else class="cart-empty">
            <MarkdownBlock :text="$gettext('emptyCartLabel')" tag="p" inline />
            <router-link
              :to="{
                name: 'Giardino25Product',
                params:{
                  productId: Giardino25Content.settings.cart.emptyCartProductID
                }
              }"
              @click.native="$root.shopifyCartOpen = false"
            >
              {{ $gettext('discoverInShopLabel') }}
            </router-link>
          </div>
        </div>
        <div class="cart-footer">
          <small>{{ $gettext('shippingFeesDisclaimer') }}</small>
          <button @click="cartStep = 1" :disabled="Cart.length === 0 || cartStep === 1" type="button">
            {{ $gettext('checkout') }}
            <svg data-v-8d1ca8bc="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 60" preserveAspectRatio="none"><path data-v-8d1ca8bc="" vector-effect="non-scaling-stroke" d="M16.7,13.4C26.8,6.9,42.7,2.7,60,2.7c29.7,0,55,12.2,55,27.3S90.4,57.3,60,57.3S5,44.9,5,30C5,23.8,9.4,18,16.7,13.4"></path></svg>
          </button>
        </div>
      </div>
      <div class="cart-step-last">
        <div class="cart-header">
          <button @click="cartStep = 0" :disabled="cartStep === 0" type="button" class="cart-back-button">{{$gettext('back')}}</button>
        </div>
        <div class="cart-main">
          <div class="cart-checkbox">
            <input v-model="checked" type="checkbox" name="consent" id="consent" class="mr-4">
            <MarkdownBlock :text="$gettext('checkoutConsentText')" tag="label" for="consent" linkify inline />
          </div>
          <button @click="readMore = !readMore" type="button" class="cart-read-more-button">
            {{readMore ? '-' : '+'}}
            {{ $gettext('readMore') }}
          </button>
          <MarkdownBlock v-show="readMore" :text="$gettext('checkoutReadMoreText')" tag="p" inline class="text-left cart-read-more-text" />
          <MarkdownBlock :text="$gettext('checkoutDisclaimerText')" tag="p" inline class="cart-read-more-text-content" />
          <!--<MarkdownBlock
            v-if="$gettext('shippingSuspendedMessage')"
            :text="$gettext('shippingSuspendedMessage')"
          />-->
        </div>
        <div class="cart-footer">
          <button :disabled="cartStep === 0 || !checked" type="submit">
            {{ $gettext('checkout') }}
            <svg data-v-8d1ca8bc="" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 120 60" preserveAspectRatio="none"><path data-v-8d1ca8bc="" vector-effect="non-scaling-stroke" d="M16.7,13.4C26.8,6.9,42.7,2.7,60,2.7c29.7,0,55,12.2,55,27.3S90.4,57.3,60,57.3S5,44.9,5,30C5,23.8,9.4,18,16.7,13.4"></path></svg>
          </button>
        </div>
      </div>
      <div :class="{ visible: $root.checkoutLoading }" class="loading"></div>
    </form>
  </aside>
</template>

<script>
import Giardino25Content from '@/assets/data/giardino'
import AddToCheckoutQuery from '@/graphql/shopify/AddToCheckout.gql'
import GetCheckoutQuery from '@/graphql/shopify/GetCheckout.gql'
import UpdateToCheckout from '@/graphql/shopify/UpdateToCheckout.gql'
import RemoveToCheckoutQuery from '@/graphql/shopify/RemoveToCheckout.gql'
import CreateCheckoutQuery from '@/graphql/shopify/CreateCheckout.gql'

import { gsap } from 'gsap'
import MarkdownBlock from '@monogrid/vue-lib/lib/components/MarkdownBlock'

import ButtonClose from '@/atoms/ButtonClose.vue'
import { find } from 'lodash'

import { formatPrice, formatTotalPrice } from '@/utils/shopify'

export default {
  name: 'Cart',
  components: { MarkdownBlock, ButtonClose },
  apollo: {
    Checkout: {
      client: 'shopifyClient',
      skip () {
        return !this.$root.checkoutID
      },
      pollInterval: 15000,
      query: GetCheckoutQuery,
      variables () {
        return {
          id: this.$root.checkoutID,
          imageWidth: 250
        }
      },
      update: data => data.Checkout,
      async result (result) {
        if (!result.data) {
          this.$root.checkoutID = null
          localStorage.removeItem('shopifyCheckout')
          this.getCheckoutInstance()
          return
        }
        if (result.data.Checkout.completedAt) {
          this.$root.checkoutID = null
        }
        this.addCheckoutIDToURL()
      }
    }
  },
  data () {
    return {
      formatPrice,
      Giardino25Content,
      cartStep: 0,
      checked: localStorage.getItem('cartGeneralSaleConditions') || false,
      readMore: false,
      firstStepTimeline: null,
      lastStepTimeline: null
    }
  },
  beforeDestroy () {
    this.firstStepTimeline && this.firstStepTimeline.kill()
    this.lastStepTimeline && this.lastStepTimeline.kill()
  },
  async mounted () {
    await this.$nextTick()
    this.createFirstStepTimeline()
    this.createLastStepTimeline()
    this.$events.on('refresh-checkout', this.forceRefresh)
    this.$events.on('add-checkout-item', this.cartAddItem)
    this.$events.on('update-checkout-item-quantity', this.onCartItemQuantityUpdate)

    if (this.$root.checkoutID) {
      this.getCheckoutInstance()
    }
  },
  computed: {
    Cart () {
      return this.Checkout && this.Checkout.lineItems.edges
    },
    CartLengthLabel () {
      return this.Cart.length < 10 ? `0${this.Cart.length}` : this.Cart.length
    },
    CartQuantity () {
      return this.Cart
        ? this.Cart.reduce((acc, { node }) => {
          acc += node.quantity
          return acc
        }, 0)
        : 0
    },
    CartTotalPrice () {
      return this.Checkout && formatTotalPrice(this.Checkout)
    },
    CartURL () {
      return this.Checkout && this.Checkout.webUrl
    }
  },
  methods: {
    async getCheckoutInstance () {
      if (!this.$root.checkoutID) {
        try {
          const mutation = await this.$apollo.mutate({
            client: 'shopifyClient',
            mutation: CreateCheckoutQuery
          })

          this.$root.checkoutID = mutation.data.checkoutCreate.checkout.id
          document.cookie = `shopifyCheckout=${this.$root.checkoutID}; path=/; max-age=${60 * 60 * 24 * 10};`
        } catch (err) {
          console.log(err)
          // setTimeout(this.getCheckoutInstance, 200)
        }
      }
    },
    createFirstStepTimeline () {
      this.firstStepTimeline = gsap.timeline({ paused: true })
      this.firstStepTimeline.fromTo(this.$el, { autoAlpha: 0 }, { autoAlpha: 1, duration: 0.3, ease: 'Power4.easeOut' })
      this.firstStepTimeline.fromTo(this.$el.querySelector('.cart-content'), { x: '100%' }, { x: 0, duration: 0.3, ease: 'Power4.easeOut' })
    },
    createLastStepTimeline () {
      this.lastStepTimeline = gsap.timeline({
        paused: true,
        onReverseCompleteParams: [this],
        onReverseComplete: (_this) => {
          _this.checked = false
          _this.readMore = false
        }
      })
      this.lastStepTimeline.fromTo(this.$el.querySelector('.cart-step-last'), { x: '100%' }, { x: 0, duration: 0.3, ease: 'Power4.easeOut' })
    },
    async addCheckoutIDToURL () {
      await this.$nextTick()
      const a = this.$el.querySelector('.cart-new-products-discalimer a')
      if (a) {
        const newHref = a.href + '/?checkout=' + this.$root.checkoutID
        a.href = newHref
      }
    },
    async forceRefresh () {
      await this.$apollo.queries.Checkout.refetch()
      if (!this.CartQuantity) localStorage.removeItem('shopifyCheckout')
      this.$root.checkoutLoading = false
    },
    async cartAddItem ({ variantId, quantity = 1 }) {
      this.$root.checkoutLoading = true

      try {
        const mutation = await this.$apollo.mutate({
          client: 'shopifyClient',
          mutation: AddToCheckoutQuery,
          variables: {
            checkoutId: this.$root.checkoutID,
            lineItems: [
              {
                variantId,
                quantity
              }
            ]
          }
        })
        this.$root.checkoutID = mutation.data.checkoutLineItemsAdd.checkout.id
        localStorage.setItem('shopifyCheckout', this.$root.checkoutID)
        this.forceRefresh()
        this.$events.emit('checkout-item-added')

        // this.$root.pushAnalyticsEvent('click_on_add_to_cart', 'Click su aggiungi al carrello', '', `ID: ${this.currentProduct.id} - Nome: ${this.ShopifyProduct.title} - Taglia: ${this.currentVariantNode.title}`, `${this.currentVariantNode.priceV2.amount} ${this.currentVariantNode.priceV2.currencyCode}`)
      } catch (err) {
        console.log(err)
        this.$root.checkoutLoading = false
      }
    },
    async cartUpdateItem (nodeID, quantity) {
      this.$root.checkoutLoading = true

      try {
        const mutation = await this.$apollo.mutate({
          client: 'shopifyClient',
          mutation: UpdateToCheckout,
          variables: {
            checkoutId: this.$root.checkoutID,
            lineItems: {
              id: nodeID,
              quantity
            }
          }
        })
        this.$root.checkoutID = mutation.data.checkoutLineItemsUpdate.checkout.id
        localStorage.setItem('shopifyCheckout', this.$root.checkoutID)
        this.forceRefresh()
        this.$events.emit('checkout-item-updated', quantity)
      } catch (err) {
        console.log(err)
        this.$root.checkoutLoading = false
      }
    },
    async cartRemoveItem (nodeID) {
      this.$root.checkoutLoading = true

      try {
        const mutation = await this.$apollo.mutate({
          client: 'shopifyClient',
          mutation: RemoveToCheckoutQuery,
          variables: {
            checkoutId: this.$root.checkoutID,
            lineItemIds: [nodeID]
          }
        })
        this.$root.checkoutID = mutation.data.checkoutLineItemsRemove.checkout.id
        localStorage.setItem('shopifyCheckout', this.$root.checkoutID)
        this.forceRefresh()
        this.$events.emit('checkout-item-removed')
      } catch (err) {
        console.log(err)
        this.$root.checkoutLoading = false
      }
    },
    onCartSubmit () {
      window.open(this.CartURL, '_blank')
    },
    onCartItemQuantityUpdate ({ variantID, quantity }) {
      const { node } = find(this.Cart, ({ node }) => node.variant.id === variantID)
      this.cartUpdateItem(node.id, quantity)
    }
  },
  watch: {
    '$root.checkoutID': {
      immediate: true,
      async handler (value) {
        await this.getCheckoutInstance()
      }
    },
    '$language.current' (newValue, oldValue) {
      this.addCheckoutIDToURL()
    },
    '$root.shopifyCartOpen' (newValue, oldValue) {
      newValue ? this.firstStepTimeline.play() : this.firstStepTimeline.reverse()
    },
    cartStep (newValue, oldValue) {
      newValue ? this.lastStepTimeline.play() : this.lastStepTimeline.reverse()
    },
    checked (newValue, oldValue) {
      newValue
        ? localStorage.setItem('cartGeneralSaleConditions', newValue)
        : localStorage.removeItem('cartGeneralSaleConditions')
    }
  }
}
</script>

<style lang="scss" scoped>
.hidden-iframe {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}

aside {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $z-cart;
  overflow: hidden;
}

.cart-background {
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($c-black, 40%);
}

.cart-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 30%;
  height: 100%;
  background-color: $c-white;

  @include breakpoint ('lg-only') {
    width: 35%;
  }

  @include breakpoint ('md-only') {
    width: 50%;
  }

  @include breakpoint ('sm-and-down') {
    width: 88%;
  }
}

.loading {
  background-color: rgba($c-black, 20%);
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-out;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }
}

.cart-step-first {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-step-last {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: inherit;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-header {
  height: 10vh;
  padding: 0 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include breakpoint ('sm-and-down') {
    padding: 0 28px;
  }

  .cart {
    font-size: 1.14vw;
    text-transform: uppercase;
    margin: 0;

    @include breakpoint ('sm-and-down') {
      font-size: 18px;
    }

    span {
      color: #829f0c;
    }
  }

  .cart-step-last & {
    justify-content: center;
  }

  ::v-deep button {
    transform: scale(0.8);
  }
}

.cart-main {
  height: 75vh;
  padding: 24px 48px 48px;
  overflow-x: hidden;
  overflow-y: auto;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
  scroll-behavior: smooth;

  @include breakpoint ('sm-and-down') {
    padding: 24px 28px 48px;
  }

  &::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .cart-step-last & {
    padding: 0 48px 48px;
  }
}

.cart-product {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  &:not(:last-child) {
    padding-bottom: 24px;
  }

  img {
    width: 115px;
    height: 140px;
    object-fit: cover;
    margin-right: 24px;
    object-position: center;
    pointer-events: none;
    user-select: none;
  }

  .product-info {
    width: 100%;
  }

  .product-title,
  .product-price {
    display: block;
    font-weight: 700;
  }

  .product-title {
    margin-bottom: 0.5em;
  }

  .product-price {
    margin-bottom: 1em;
  }

  .product-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    opacity: 0.8;
  }

  .product-quantity button:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

.cart-empty {
  margin-top: 15%;
  text-align: center;

  p {
    margin-bottom: 8%;
  }

  a {
    color: inherit;
    text-decoration: underline;
    font-weight: 700;
    font-size: 20px;

    @include breakpoint ('sm-and-down') {
      font-size: 14px;
    }
  }
}

.cart-new-products-discalimer {
  opacity: 0.8;
  margin-top: 1em;
  padding-bottom: 1em;
  border-bottom: 1px solid $c-black;

  @include breakpoint ('sm-only') {
    font-size: 2.6vw;
  }

  @include breakpoint ('xs-only') {
    font-size: 3.2vw;
  }

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.cart-total-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1em;

  span {
    font-weight: 700;
    text-transform: uppercase;

    @include breakpoint ('sm-only') {
      font-size: 3vw;
    }

    @include breakpoint ('xs-only') {
      font-size: 5vw;
    }
  }
}

.cart-footer {
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $c-black;
  text-align: center;

  small {
    display: block;
    font-size: 12px;
    opacity: 0.8;
  }

  button {
    width: 110px;
    height: 50px;
    color: inherit;
    position: relative;
    text-transform: uppercase;
    transition: opacity 0.3s ease-out;

    &:disabled {
      opacity: 0.2;
      pointer-events: none;
    }
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    fill: none;
    stroke: #829f0c;
    stroke-width: 1px;
    transform: translate(-50%, -50%);
    pointer-events: none;
    overflow: visible;
  }
}

.cart-back-button,
.cart-read-more-button {
  font-size: 18px;
  font-weight: 700;
}

.cart-checkbox {
  display: flex;
  align-items: baseline;
  margin: 36px 0;

  input[type=checkbox] {
    color: currentColor;
    flex-shrink: 0;
    appearance: none;
    position: relative;
    background-color: transparent;
    border: 1px solid currentColor;
    width: 14px;
    height: 14px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      opacity: 0;
      background-color: currentColor;
      width: 4px;
      height: 4px;
      border-radius: inherit;
      transition: all 0.3s ease-out;
    }

    &:checked::after {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }

  a {
    color: inherit;
    font-weight: 700;
    text-decoration: underline;
  }
}

.cart-discover-cta a {
  display: inline-block;
  margin: 24px auto;
  color: inherit;
  font-size: 18px;
  font-weight: 700;
}

.cart-read-more-text,
.cart-read-more-text-content {
  margin: 24px 0;

  a {
    color: inherit;
    font-weight: 700;
    text-decoration: underline;
  }
}
</style>
