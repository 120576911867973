// id = shopify ID
import VoucherImage from '@/assets/img/giardino25/products/voucher.jpg'
import SecondaAnniversaryImage from '@/assets/img/giardino25/products/second-anniversary.jpg'

export default [
  {
    id: 'giardino-25s-second-anniversary-celebration',
    availableForSale: false,
    booking: {
      email: {
        address: 'giardino.25@gucci.com',
        subject: 'Da Web: Prenotazione Tavolo evento 15.02',
        body: ''
      }
    },
    category: { id: 1, label: 'experience' },
    enTitle: 'An exclusive event to celebrate our second anniversary',
    itTitle: 'Un evento esclusivo per celebrare il nostro secondo anniversario',
    enAlternativeTitle: 'GIARDINO 25 SECOND ANNIVERSARY',
    itAlternativeTitle: 'SECONDO ANNIVERSARIO DI GIARDINO 25',
    image: SecondaAnniversaryImage,
    enDescription: 'Join us for an evening of Bar Manager Martina Bonci’s signature cocktails, as well as a special drink list curated by Simone Onorati of Jerry Thomas bar in Rome, seasonal bites, and a special DJ set to honor this momentous occasion.  \n\nThursday, February 15th  \nFrom 6.30pm  \nAt Gucci Giardino',
    itDescription: 'Raggiungici per una serata in compagnia degli iconici cocktail della nostra Bar Manager Martina Bonci, insieme a una drink list curata da Simone Onorati del bar Jerry Thomas di Roma, stuzzichini sfiziosi e uno speciale DJ set per festeggiare questa occasione da ricordare.  \n\nGiovedì, 15 febbraio  \nDalle 18.30  \nDa Gucci Giardino',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/experiences/giardino-25-second-anniversary',
      it: 'https://www.gucciosteria.com/it/florence/giardino/experiences/giardino-25-second-anniversary'
    }
  },
  {
    isMainProduct: true,
    category: { id: 2, label: 'product' },
    id: '8038726861082',
    _slug: '8038726861082',
    title: '8038726861082',
    formSKU: 'Mémoire di Negroni ',
    productOptionList: null,
    itTitle: 'Mémoire di Negroni di Martina Bonci',
    enTitle: 'Mémoire di Negroni by Martina Bonci',
    frTitle: 'Mémoire di Negroni, par Martina Bonci',
    jaTitle: 'メモワール ディ ネグローニ by マルティーナ・ボンチ',
    zhTitle: 'Martina Bonci制作“Mémoire di Negroni”鸡尾酒',
    itAlternativeTitle: null,
    enAlternativeTitle: null,
    frAlternativeTitle: null,
    jaAlternativeTitle: null,
    zhAlternativeTitle: null,
    image: null,
    itDescription: "Variante deliziosa del classico cocktail Negroni creato da Martina Bonci, Head Mixologist di Gucci Giardino. Fondendo il colore di Firenze, luogo di nascita del Negroni e di Gucci, con ingredienti inaspettati, Martina Bonci reinventa il classico cocktail da aperitivo rimanendo fedele alle sue origini. \\\n\\\nMémoire di Negroni unisce ingredienti premium come il Gin Peter in Florence, il Bitter Bianco Luxardo, il Martini Vermouth Ambrato, il sake allo yuzu, il bitter al pompelmo The Bitter Truth e il butterfly pea flower, miscelati in un drink dal colore viola intenso, la cui complessità si svela sorso dopo sorso, presentato in un'elegante bottiglia che aggiungerà un tocco sofisticato al tuo angolo bar. \\\n\\\nQuesto prodotto è disponibile per l'acquisto online con consegna in Italia, presso la Gucci Osteria di Firenze e presso Gucci Giardino. Per informazioni sugli altri paesi, ti preghiamo di contattarci.",
    enDescription: 'A fantastical twist on the classic Negroni cocktail created by Head Mixologist Martina Bonci of Gucci Giardino. Fusing the color of Florence - birthplace of the negroni and Gucci - with unexpected ingredients, she reimagines the classic aperitivo cocktail while remaining faithful to its flavorful origins. \\\n\\\nMémoire di Negroni brings together premium ingredients such as Peter in Florence Gin, Luxardo Bitter Bianco, Martini Vermouth Ambrato, yuzu sake, The Bitter Truth grapefruit bitters, and butterfly pea flower, in a deep, purple-colored drink of complexity that reveals itself sip after sip. It is presented in a elegant bottle designed to elevate your home bar collection. \\\n\\\nThis product is available to purchase online with delivery to Italy, at Gucci Osteria Florence, and at Gucci Giardino. For all other countries, please contact us.\\\n\\\n***When purchasing alcoholic beverages, you will be automatically redirected to our partner to complete the sale.***',
    frDescription: "Une réinvention fantastique du classique Negroni, imaginée par Martina Bonci, mixologue au Gucci Giardino. Associant les couleurs de Florence (berceau du Negroni et de Gucci) et des ingrédients inattendus, elle parvient à revisiter le cocktail apéritif classique tout en restant fidèle à ses origines pleines de saveurs. \\\n\\\nMémoire di Negroni se compose des meilleurs ingrédients, notamment le gin Peter in Florence, le Luxardo Bitter Bianco, le Vermouth Ambrato Martini , le saké au yuzu, le bitter au pamplemousse The Bitter Truth et le pois bleu, pour créer un breuvage d’un violet profond dont les saveurs se révèlent gorgée après gorgée. Il est présenté dans une élégante bouteille qui deviendra l’un des joyaux de votre bar. \\\n\\\nCe produit est disponible à l’achat en ligne pour livraison en Italie, à la Gucci Osteria Florence et au Gucci Giardino. Pour les livraisons dans d’autres pays, veuillez nous contacter.\\\n\\\n***Lors de l'achat de boissons alcoolisées, vous serez automatiquement redirigé vers notre partenaire pour finaliser la vente.***",
    jaDescription: 'Gucci Giardinoのヘッド ミクソロジストであるマルティーナ・ボンチが考案した、クラシックなネグローニに幻想的なひねりを効かせたカクテル。ネグローニとグッチの発祥の地であるフィレンツェの色彩と、意外性に満ちた素材を融合させることで、クラシックなカクテルを本来の香り高さに忠実でありながら新しいアペリティーヴォに仕上げています。\\\n\\\nPeter in Florence Gin（ロンドン ドライ ジン）、Luxardo Bitter Bianco（リキュール）、Martini Vermouth Ambrato（マティーニ）、柚子酒、The Bitter Truth（グレープフルーツ ビターズ）、バタフライピー フラワーをはじめとする上質な材料をミックスした「Mémoire di Negroni（メモワール ディ ネグローニ）」は、口に含む度にその複雑な味わいが広がる、深い紫色が美しいカクテルです。\\\n\\\nご自宅のホームバーを華やかに彩る、エレガントなボトル入り。 この製品はオンラインでも購入可能で、イタリア国内への発送を承ります。グッチ オステリア フィレンツェ、Gucci Giardinoでもお買い求めいただけます。イタリア国外への配送に関してはお問い合わせください。',
    zhDescription: '由Gucci Giardino的首席调酒师Martina Bonci制作的经典Negroni鸡尾酒口感独特。她巧妙融合佛罗伦萨（negroni鸡尾酒和Gucci的发源地）色彩与独特成分，在保持原本口感的同时为这款经典餐前鸡尾酒注入新意品味。\\\n\\\nPeter in Florence金酒、路萨朵白苦味配制酒、马天尼琥珀色味美思酒、柚子清酒、比特储斯柚子味苦味酒和蝶豆花等优质基酒共同调制，最终呈现深紫色的混合口味Mémoire di Negroni鸡尾酒，细细品酌享受多层次口感。这款鸡尾酒采用优雅瓶身设计，提升您的家庭吧台格调。\\\n\\\n此产品支持在线购买，配送至意大利的Gucci Osteria佛罗伦萨餐厅和Gucci Giardino餐厅。欲了解其他国家和地区的配送信息，敬请联系我们。',
    enWpUrl: 'https://spirits.gucciosteria.com/gb/eur/en/products/memoire-di-negroni-by-martina-bonci.html',
    frWpUrl: 'https://spirits.gucciosteria.com/fr/eur/en/products/memoire-di-negroni-by-martina-bonci.html',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/shop/food-and-spirits/memoire-di-negroni-by-martina-bonci/',
      it: 'https://www.gucciosteria.com/it/florence/shop/food-and-spirits/memoire-di-negroni-by-martina-bonci/'
    }
  },
  {
    isMainProduct: true,
    category: { id: 2, label: 'product' },
    id: '7152823795892',
    _slug: '7152823795892',
    title: '7152823795892',
    formSKU: 'Elicriso',
    productOptionList: null,
    itTitle: "Elisir d'Elicriso di Giorgio Bargiani",
    enTitle: "Elisir d'Elicriso by Giorgio Bargiani",
    frTitle: "Elisir d'Elicriso par Giorgio Bargiani",
    jaTitle: 'ジョルジオ・バルジャーニによるエリジール デリクリーゾ',
    zhTitle: "Giorgio Bargiani创作的Elisir d'Elicriso",
    itAlternativeTitle: null,
    enAlternativeTitle: null,
    frAlternativeTitle: null,
    jaAlternativeTitle: null,
    zhAlternativeTitle: null,
    image: null,
    itDescription: "Creato per Giardino 25 in collaborazione con Giorgio Bargiani di The Connaught Bar a Londra, Elisir d'Elicriso è un nuovo cocktail da collezione, con un profilo ricco, aromatico e rotondo, a base di piante locali ed erbe naturali e officinali del Mediterraneo, vetiver e olio essenziale di \"elicriso\" (helichrysum italicum). Scopri un'esperienza sensoriale che evoca i rigogliosi giardini toscani attraverso un mix perfettamente bilanciato, complesso ma allo stesso tempo leggero e dolce al palato.\\\n\\\nQuesto prodotto è disponibile per l'acquisto online con consegna in Italia, presso la Gucci Osteria di Firenze e presso Gucci Giardino. Per informazioni sugli altri paesi, ti preghiamo di contattarci.",
    enDescription: "Created in collaboration with Giorgio Bargiani of The Connaught Bar in London for Gucci Giardino, the Elisir d'Elicriso is a new bespoke collector’s cocktail with a rich, herbal, and rounded profile drawn from local botanicals, apothecary and natural herbs from the Mediterranean, vetiver, and the essential oil of “elicriso” (helichrysum italicum). Discover a sensorial product that evokes the lush gardens of Tuscany with this perfectly balanced blend that is complex yet light and sweet on the palate.\\\n\\\nThis product is available to purchase online with delivery to Italy, at Gucci Osteria Florence, and at Gucci Giardino. For all other countries, please contact us.\\\n\\\n***When purchasing alcoholic beverages, you will be automatically redirected to our partner to complete the sale.***",
    frDescription: "Créé en collaboration avec Giorgio Bargiani du Connaught Bar à Londres pour Gucci Giardino, l'Elisir d'Elicriso est un nouveau cocktail au profil rond, herbacé et riche, conçu sur mesure à partir de plantes locales, d'herbes naturelles et d'apothicaire de Méditerranée, de vétiver, et d'huile essentielle d'« elicriso » (helichrysum italicum). Vivez une expérience sensorielle qui évoque les jardins luxuriants de la Toscane avec ce mélange parfaitement équilibré à la fois léger, complexe et doux en bouche.\\\n\\\nCe modèle est disponible en ligne avec livraison en Italie, à Gucci Osteria Florence et à Gucci Giardino. Pour les autres pays, veuillez nous contacter.\\\n\\\n***Lors de l'achat de boissons alcoolisées, vous serez automatiquement redirigé vers notre partenaire pour finaliser la vente.***",
    jaDescription: 'ロンドンのConnaught Barのジョルジオ・バルジャーニとのコラボレーションにより、Gucci Giardinoの誕生を祝して特別に作られた限定カクテル「エリジール デリクリーゾ」。地元トスカーナの植物、地中海沿岸原産の薬草やハーブ、ベチバー、そしてカクテル名の由来でもある植物「ヘリクリサム」（学名 helichrysum italicum）のエッセンシャルオイルを用いたこのカクテルは、ハーブの豊かな個性がまろやかに調和しています。トスカーナの緑豊かなガーデンを思い起こさせるこのパーフェクトなバランスのブレンドは、複雑性を秘めつつも軽やかな口当たりで、五感を虜にします。\\\n\\\nこの製品は、オンラインショップ（イタリア国内のみ配送可能）、グッチ オステリア フィレンツェ、Gucci Giardinoにてお買い求めいただけます。その他の地域については、お問い合わせください。',
    zhDescription: "Gucci Giardino与来自伦敦康诺特酒吧的Giorgio Bargiani合作，打造出全新的Elisir d'Elicriso定制收藏款鸡尾酒。此款鸡尾酒采用当地植物、药材、地中海天然草药、香根草以及意大利蜡菊精油，具有丰润的草本质地。巧妙融合复杂而轻甜的口感，令您仿佛置身托斯卡纳郁郁葱葱的花园，带来非凡感官体验。\\\n\\\n此产品可在线购买并配送至意大利佛罗伦萨Gucci Osteria餐厅及Gucci Giardino。如需配送至其他国家/地区，请与我们联系。",
    enWpUrl: 'https://spirits.gucciosteria.com/gb/eur/en/products/elisir-d-elicriso-by-giorgio-bargiani.html',
    frWpUrl: 'https://spirits.gucciosteria.com/fr/eur/en/products/elisir-d-elicriso-by-giorgio-bargiani.html',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/shop/food-and-spirits/elisir-delicriso/',
      it: 'https://www.gucciosteria.com/it/florence/shop/food-and-spirits/elisir-delicriso/'
    }
  },
  {
    isMainProduct: true,
    isCheckoutAlwaysAvailable: true,
    category: { id: 1, label: 'experience' },
    id: '8473348145491',
    _slug: 'timeless',
    title: 'Timeless',
    formSKU: '8473348145491',
    productOptionList: [
      {
        id: '8473348145491',
        enTitle: 'Timeless',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: 'タイムレス',
        zhTitle: '经典隽永'
      },
      {
        id: '8473348538707',
        enTitle: 'Something New',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '今までにないもの',
        zhTitle: '全新品味'
      },
      {
        id: '8473348702547',
        enTitle: 'Jigglin',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '高揚感',
        zhTitle: '多元美食'
      }
    ],
    itTitle: 'Timeless',
    enTitle: 'Timeless',
    frTitle: 'Timeless',
    jaTitle: 'タイムレス',
    zhTitle: '经典隽永',
    itAlternativeTitle: 'Degustazioni speciali',
    enAlternativeTitle: 'Tasting collections',
    frAlternativeTitle: 'Collecte des dégustations',
    jaAlternativeTitle: 'テイスティングメニュー',
    zhAlternativeTitle: '品尝菜单',
    image: VoucherImage,
    itDescription: 'Un menù degustazione che racchiude alcuni dei nostri piatti simbolo, in cui la tradizione culinaria italiana viene reinterpretata con un twist moderno, in abbinamento alcuni dei cocktail più iconici del nostro bar.\n\nMemoire di Negroni (Focaccia con Mortadella e Ricotta)\n\nChi si ferma è perduto (Ceviche di Quinoa con Scampi)\n\nFlora (Tartara di Vitellona)',
    enDescription: 'A tasting menu that showcases some of our most iconic dishes, where the timeless elements of Italian cuisine come to life with a modern flair, paired with some of the most emblematic cocktails from our bar.\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nFlora (Beef tartare, Shallot, Pickles, Celery, Marinated Egg Yolk)',
    frDescription: 'Un menu de dégustation qui met en valeur certains de nos plats les plus typiques, dans lequel les éléments intemporels de la cuisine italienne prennent vie, sublimés d’une touche de modernité.\n\nCe menu est associé aux cocktails les plus emblématiques de notre bar.\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nFlora (Beef tartare, Shallot, Pickles, Celery, Marinated Egg Yolk)',
    jaDescription: '当レストランを代表する料理を取り揃えたテイスティングメニューでは、時代を超えて受け継がれるイタリア料理の要素に現代的なセンスを加え、さらに当店のバーを象徴するカクテルと組み合わせました。\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nFlora (Beef tartare, Shallot, Pickles, Celery, Marinated Egg Yolk)',
    zhDescription: '品味菜单涵盖了我们的标志性菜式，意大利经典美食经过当代改良焕新登场，搭配酒吧招牌鸡尾酒，畅快体验美味盛宴。\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nFlora (Beef tartare, Shallot, Pickles, Celery, Marinated Egg Yolk)',
    enWpUrl: null,
    frWpUrl: null,
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/menu/tasting-menu',
      it: 'https://www.gucciosteria.com/it/florence/giardino/menu/tasting-menu'
    }
  },
  {
    isMainProduct: true,
    category: { id: 1, label: 'experience' },
    isCheckoutAlwaysAvailable: true,
    id: '8423370359123',
    _slug: 'un-brunch-esclusivo-con-il-gusto-di-xinge-al-gucci-giardino-25',
    title: 'Un brunch esclusivo con Il Gusto di Xinge al Gucci Giardino',
    formSKU: '8423370359123',
    productOptionList: null,
    enTitle: 'An exclusive brunch with Il Gusto di Xinge at Gucci Giardino',
    itTitle: 'Un brunch esclusivo con Il Gusto di Xinge al Gucci Giardino',
    frTitle: 'Un brunch exclusif avec Il Gusto di Xinge au Gucci Giardino',
    jaTitle: 'グッチ ジャルディーノ25でのイル・グスト・ディ・シンゲによるスペシャルブランチ',
    zhTitle: '莅临Gucci Giardino，享用IL GUSTO DI XINGE呈现的独家早午餐',
    enAlternativeTitle: 'BRUNCH WITH IL GUSTO DI XINGE',
    itAlternativeTitle: 'BRUNCH CON IL GUSTO DI XINGE',
    frAlternativeTitle: 'BRUNCH AVEC IL GUSTO DI XINGE',
    jaAlternativeTitle: 'イル・グスト・ディ・シンゲのブランチ',
    zhAlternativeTitle: '享用IL GUSTO DI XINGE的早午餐',
    image: null,
    enDescription: 'Gucci Giardino is delighted to be hosting an exclusive brunch in collaboration with Il Gusto di Xinge, by Chef Xinge Liu, renowned for her creative vision of Chinese cuisine that revisits culinary traditions in a new and contemporary key.The first collaboration of its kind, the brunch will offer a selection of dishes that unite traditional Chinese flavors with Italian ingredients, through a 5-course tasting menu accompanied by 5 cocktails specially prepared for the occasion by Bar Manager Martina Bonci.\n\nWednesday, June 28th\\\nFrom 12.30pm to 3.30pm\\\nAt Gucci Giardino',
    itDescription: 'Gucci Giardino ha il piacere di ospitare un brunch esclusivo in collaborazione con Il Gusto di Xinge, della Chef Xin Ge Liu, nota per la sua visione creativa della cucina cinese che rivisita le tradizioni culinarie in chiave nuova e contemporanea.Il brunch, prima collaborazione nel suo genere, propone una selezione di piatti che uniscono i sapori tradizionali cinesi agli ingredienti italiani, attraverso un menu degustazione di 5 portate abbinate a 5 cocktail, realizzati appositamente per l\'occasione dalla Bar Manager Martina Bonci.\n\nMercoledì, 28 giugno\\\nDalle 12:30 alle 15:30\\\nDa Gucci Giardino',
    frDescription: 'Gucci Giardino a le plaisir d\'organiser un brunch exclusif en collaboration avec Il Gusto di Xinge, par le chef Xinge Liu, réputé pour sa vision créative de la cuisine chinoise qui revisite les traditions culinaires avec un regard contemporain.Première collaboration de ce type, le brunch proposera une sélection de plats alliant les saveurs traditionnelles chinoises aux ingrédients italiens, à travers un menu dégustation de plats accompagné de 5 cocktails spécialement imaginés pour l\'occasion par Martina Bonci, gérante du bar. \n\nMercredi 28 juin\\\nDe 12h30 à 15h30\\\nÀ Gucci Giardino',
    jaDescription: '「Gucci Giardino（グッチ ジャルディーノ 25）」では、伝統的な中国料理を現代的にアレンジした独創的な料理で知られるシェフ、シンゲ・リウによる「イル・グスト・ディ・シンゲ」とのコラボレーションによるスペシャルブランチを開催いたします。この種のコラボレーションの第一弾となるブランチでは、バー・マネージャーのマルティーナ・ボンチがこの日のために特別に考案した5種類のカクテルとともに、伝統的な中国の味とイタリアの食材を融合させた料理の数々を、5品のテイスティングメニューのコースでお楽しみいただけます。 \n\n6月28日（水）\\\n12時30分から15時30分まで\\\nグッチ ジャルディーノ25にて',
    zhDescription: 'Gucci Giardino很高兴能够与Il Gusto di Xinge携手，举办专属早午餐宴。主厨Xinge Liu凭借其对中国美食的创意视野而闻名，以全新的现代风格重新演绎烹饪传统。此次早午餐宴是首度同类合作，将提供一系列结合传统中国风味与意大利食材的菜肴，包括5道菜品鉴菜单以及酒吧经理Martina Bonci专为此场合调制的5种鸡尾酒。\n\n6月28日（周三）\\\n中午12点30分至下午3点30分\\\n地点：Gucci Giardino',
    enWpUrl: null,
    frWpUrl: null,
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/experiences/brunch-with-il-gusto-di-xinge',
      it: 'https://www.gucciosteria.com/it/florence/giardino/experiences/brunch-with-il-gusto-di-xinge/'
    }
  },
  {
    isCheckoutAlwaysAvailable: true,
    category: { id: 2, label: 'product' },
    id: '8473348702547',
    _slug: 'jigglin',
    title: 'Jigglin',
    formSKU: '8473348702547',
    productOptionList: [
      {
        id: '8473348702547',
        enTitle: 'Jigglin',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '高揚感',
        zhTitle: '多元美食'
      },
      {
        id: '8473348145491',
        enTitle: 'Timeless',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: 'タイムレス',
        zhTitle: '经典隽永'
      },
      {
        id: '8473348538707',
        enTitle: 'Something New',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '今までにないもの',
        zhTitle: '全新品味'
      }
    ],
    itTitle: 'Jigglin',
    enTitle: 'Jigglin',
    frTitle: 'Jigglin',
    jaTitle: '高揚感',
    zhTitle: '多元美食',
    itAlternativeTitle: null,
    enAlternativeTitle: null,
    frAlternativeTitle: null,
    jaAlternativeTitle: null,
    zhAlternativeTitle: null,
    image: null,
    itDescription: 'Un menù degustazione che si snoda attraverso alcuni dei sapori italiani più iconici: un tour gastronomico tra piatti senza tempo che include i migliori ingredienti stagionali e alcuni dei cocktail più iconici del nostro bar.\n\nMemoire di Negroni (Focaccia con Mortadella e Ricotta)\n\nChi si ferma è perduto (Ceviche di Quinoa con Scampi)\n\nTi portiamo al mare (Spaghetto con Cavolo nero e Scampi)\n\nQueen Bee (Craquelin di Caterina)',
    enDescription: 'A tasting menu that meanders through some of the most iconic Italian flavors for a gastronomic tour of timeless dishes that incorporate the very best seasonal ingredients, paired with some of the most emblematic cocktails from our bar.\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nTi portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)\n\nQueen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    frDescription: 'Un menu de dégustation qui explore certaines saveurs phares de la cuisine italienne, pour une expérience gastronomique mettant en avant des plats intemporels qui incorporent les meilleurs ingrédients de saison, le tout associé aux cocktails les plus emblématiques de notre bar.\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nTi portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)\n\nQueen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    jaDescription: 'テイスティングメニューでは、イタリアを代表する味覚の数々をご堪能いただけます。旬の食材をふんだんに使ったタイムレスな料理と、当店を代表するカクテルをお楽しみください。\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nTi portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)\n\nQueen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    zhDescription: '您可以体验经典的意大利风味，品尝融合上佳时令食材的招牌菜品，搭配酒吧招牌鸡尾酒，开启丰富的美食之旅。\n\nMemoire di Negroni (Focaccia with mortadella, ricotta and M.B. balsamic vinegar)\n\nChi si ferma è perduto (Ceviche, prawns*, quinoa, passion fruit)\n\nTi portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)\n\nQueen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    enWpUrl: null,
    frWpUrl: null,
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/menu/tasting-menu',
      it: 'https://www.gucciosteria.com/it/florence/giardino/menu/tasting-menu'
    }
  },
  {
    isCheckoutAlwaysAvailable: true,
    category: { id: 2, label: 'product' },
    id: '8473348538707',
    _slug: 'something-new',
    title: 'Something New',
    formSKU: '8473348538707',
    productOptionList: [
      {
        id: '8473348538707',
        enTitle: 'Something New',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '今までにないもの',
        zhTitle: '全新品味'
      },
      {
        id: '8473348145491',
        enTitle: 'Timeless',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: 'タイムレス',
        zhTitle: '经典隽永'
      },
      {
        id: '8473348702547',
        enTitle: 'Jigglin',
        itTitle: undefined,
        frTitle: undefined,
        jaTitle: '高揚感',
        zhTitle: '多元美食'
      }
    ],
    itTitle: 'Something New',
    enTitle: 'Something New',
    frTitle: 'Something New',
    jaTitle: '今までにないもの',
    zhTitle: '全新品味',
    itAlternativeTitle: null,
    enAlternativeTitle: null,
    frAlternativeTitle: null,
    jaAlternativeTitle: null,
    zhAlternativeTitle: null,
    image: null,
    itDescription: 'L’incontro dei migliori ingredienti locali dà vita a un menù degustazione che cambia in base alle stagioni, per un’esperienza di pura creatività accompagnata da alcuni dei cocktail più iconici del nostro bar.\n\nElisir di Lungavita (Ceviche di Quinoa con Scampi)Ti portiamo al mare (Spaghetto con Cavolo nero e Scampi)Queen Bee (Craquelin di Caterina)',
    enDescription: 'Bringing the very best locally sourced ingredients for a tasting menu that changes based on what the seasons provide in a glorious display of creativity, paired with some of the most emblematic cocktails from our bar.\n\nElisir di Lungavita (Ceviche, prawns*, quinoa, passion fruit)Ti portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)Queen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    frDescription: 'Avec ce menu de dégustation qui évolue en fonction des saisons, imaginé de façon créative avec nos meilleurs ingrédients locaux, vous pourrez également siroter certains des cocktails les plus emblématiques de notre bar.\n\nElisir di Lungavita (Ceviche, prawns*, quinoa, passion fruit)Ti portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)Queen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    jaDescription: 'テイスティングメニューでご用意しているのは、厳選した地元産の旬の食材を活かしたクリエイティビティあふれる料理。当店のバーを象徴するカクテルと組み合わせてお楽しみください。\n\nElisir di Lungavita (Ceviche, prawns*, quinoa, passion fruit)Ti portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)Queen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    zhDescription: '品味菜单精选上佳时令食材，搭配酒吧招牌鸡尾酒，呈现富有创意的特色美食。\n\nElisir di Lungavita (Ceviche, prawns*, quinoa, passion fruit)Ti portiamo al mare (Handmade Spaghetti with Balck Cale and Scampi)Queen Bee (Bignet, Pineapple, Cocconut, White Chocolate Mousse)',
    enWpUrl: null,
    frWpUrl: null,
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/menu/tasting-menu',
      it: 'https://www.gucciosteria.com/it/florence/giardino/menu/tasting-menu'
    }
  }
  // {
  //   isMainProduct: true,
  //   category: { id: 2, label: 'product' },
  //   id: '8160338706714',
  //   _slug: 'b6-fattoria-san-lorenzo',
  //   title: 'B6, Fattoria San Lorenzo',
  //   formSKU: '8160338706714',
  //   productOptionList: [
  //     {
  //       id: '8160338706714',
  //       itTitle: 'Bottiglia da 750ml',
  //       enTitle: '750ml bottle',
  //       frTitle: '750 ml Flasche',
  //       jaTitle: '750毫升瓶',
  //       zhTitle: '750 ml ボトル'
  //     },
  //     {
  //       id: '8160340017434',
  //       itTitle: 'Bottiglia da 1.5L - Inviata con confezione di legno',
  //       enTitle: '1.5L bottle - Presented in a wooden case',
  //       frTitle: '1,5 l Flasche – präsentiert in einer Holzkiste',
  //       jaTitle: '1.5升瓶 - 附带木质包装盒',
  //       zhTitle: '1.5 L ボトル - 木製ケース入り'
  //     }
  //   ],
  //   itTitle: 'B6, Fattoria San Lorenzo',
  //   enTitle: 'B6, Fattoria San Lorenzo',
  //   frTitle: 'B6, Fattoria San Lorenzo',
  //   jaTitle: 'B6、ファットリア・サン・ロレンツォ',
  //   zhTitle: '源自Fattoria San Lorenzo酒庄的B6葡萄酒',
  //   itAlternativeTitle: null,
  //   enAlternativeTitle: null,
  //   frAlternativeTitle: null,
  //   jaAlternativeTitle: null,
  //   zhAlternativeTitle: null,
  //   image: null,
  //   itDescription: 'Gucci Osteria presenta B6, dalla cantina Fattoria San Lorenzo di Montecarotto, Ancona, una delle cantine più importanti nella produzione di vino naturale in Italia. B6 nasce dagli esperimenti di Natalino Crognaletti, proprietario della tenuta e mente creativa di un processo che vede la combinazione di sei diverse annate (2014-2015-2016-2017-2018-2019) del suo vino Campo delle Oche, prodotto con uva Verdicchio.\n\nGrazie alle sue antiche tecniche di viticoltura naturale, la cantina Fattoria San Lorenzo produce dal 1995 alcune delle più affascinanti espressioni di Verdicchio della regione. Il B6 incarna la ricerca personale del fondatore di cogliere le molteplici sfumature organolettiche delle varie annate e di raggiungere un equilibrio difficile da ottenere con una sola annata. Il risultato è un vino unico, innovativo e complesso, con note minerali di pietra focaia e incenso, leggeri sentori floreali che giocano con fresche note verdi, completati da un tocco di acidità che apre il palato.',
  //   enDescription: 'Gucci Osteria presents B6 from Fattoria San Lorenzo winery of Montecarotto, Ancona, one of the most important producers of natural wine in Italy. B6 was born from the experiments of Natalino Crognaletti, owner of the estate and creative mind behind a process that involves combining six different vintages: 2014-2015-2016-2017-2018-2019 from his Campo delle Oche wine, made from Verdicchio grapes.\n\nHeralding age-old, natural viniculture techniques, Fattoria San Lorenzo has been producing some of the region\'s most fascinating expressions of Verdicchio since 1995. B6 embodies the founder’s personal quest to capture the many organoleptic nuances of the various years and to achieve a balance unlikely offered by a single vintage. The result is a unique, innovative and complex wine with mineral notes of flint and incense, light floral hints that play with fresh green notes, rounded off with a touch of acidity that opens up the palate.',
  //   frDescription: 'Gucci Osteria présente le B6 du vignoble Fattoria San Lorenzo de Montecarotto, Ancona, l’un des plus importants producteurs de vin naturel d’Italie. Le B6 est né des expériences de Natalino Crognaletti, propriétaire du domaine et créateur d’un processus qui consiste à combiner six millésimes différents : 2014-2015-2016-2017-2018-2019 de son vin Campo delle Oche, fabriqué à partir du cépage Verdicchio.\n\nFière de ses techniques de viticulture naturelles ancestrales, la Fattoria San Lorenzo produit certaines des expressions les plus fascinantes de Verdicchio depuis 1995. Le B6 incarne la quête personnelle du fondateur : capturer les nombreuses nuances organoleptiques des différentes années afin de parvenir à l’équilibre improbable offert par un seul millésime. Il en résulte un vin unique, innovant et complexe avec des notes minérales de silex et d’encens, des notes florales légères qui jouent avec des notes végétales fraîches, apportant une rondeur en bouche sur une touche d’acidité qui ouvre le palais.',
  //   jaDescription: 'グッチ オステリアから、イタリアにおける自然派ワインの最も有力な生産者のひとつであるファットリア・サン・ロレンツォの「B6」を紹介します。マルケ州アンコーナ県モンテカロットに拠点を置く同ワイナリーのオーナー、ナタリーノ・クロニャレッティ氏の実験から生まれたワイン「B6」は、ヴェルディッキオ種のブドウを使ったワインである「カンポ デッレ オケ」の2014、2015、2016、2017、2018、2019年という6種類の異なるヴィンテージをブレンドした、彼の創造力の賜物です。\n\nファットリア・サン・ロレンツォは昔ながらのビオディナミ農法により、1995年からヴェルディッキオ種を使った魅力的なワインを生産しています。B6には、さまざまなヴィンテージが持つ感覚を刺激するニュアンスを捉え、単一のヴィンテージでは表現できない調和を図るというオーナーの個人的な探求心を体現しています。その結果から生まれたこのワインは、革新的で複雑な独自の味わいに仕上がっており、火打石やインセンスなどのミネラル感や、軽いフローラルの香り、フレッシュグリーンのノートが感じられ、まろやかな酸味が口の中に広がります。',
  //   zhDescription: 'Gucci Osteria餐厅推出选自安科纳蒙特卡罗托市Fattoria San Lorenzo酒庄的B6葡萄酒，该酒庄是意大利久负盛名的天然葡萄酒生产商。酒庄主人Natalino Crognaletti在重重试验中研发B6葡萄酒在，以创意巧思将2014-2015-2016-2017-2018-2019不同年份的六款Campo delle Oche葡萄酒巧妙混合，采用维蒂奇诺葡萄酿制，入口香醇。\n\n自1995年以来，Fattoria San Lorenzo酒庄采用古老的天然葡萄栽培技术，生产当地最为香甜诱人的维蒂奇诺葡萄。B6葡萄酒承载着庄主的个人期望，捕捉不同年份红酒之间带来的细微感官差异，实现单一葡萄酒无法带来的平衡口感。这款口味独特且富含新意的混合葡萄酒油然而生，燧石和熏香的矿物质香气交织淡淡的花香与绿叶的清新，呈现微酸口感，引人陶醉。',
  //   enWpUrl: 'https://spirits.gucciosteria.com/gb/gbp/en/products/b6-fattoria-san-lorenzo.html',
  //   frWpUrl: 'https://spirits.gucciosteria.com/fr/eur/en/products/b6-fattoria-san-lorenzo.html'
  // },
  // {
  //   category: { id: 2, label: 'product' },
  //   id: '8160340017434',
  //   _slug: 'bottiglia-da-1.5L-inviata-con-confezione-di-legno',
  //   title: 'Bottiglia da 1.5L - Inviata con confezione di legno',
  //   formSKU: '8160340017434',
  //   productOptionList: [
  //     {
  //       id: '8160340017434',
  //       itTitle: 'Bottiglia da 1.5L - Inviata con confezione di legno',
  //       enTitle: '1.5L bottle - Presented in a wooden case',
  //       frTitle: '1,5 l Flasche – präsentiert in einer Holzkiste',
  //       jaTitle: '1.5升瓶 - 附带木质包装盒',
  //       zhTitle: '1.5 L ボトル - 木製ケース入り'
  //     },
  //     {
  //       id: '8160338706714',
  //       itTitle: 'Bottiglia da 750ml',
  //       enTitle: '750ml bottle',
  //       frTitle: '750 ml Flasche',
  //       jaTitle: '750毫升瓶',
  //       zhTitle: '750 ml ボトル'
  //     }
  //   ],
  //   itTitle: 'B6, Fattoria San Lorenzo',
  //   enTitle: 'B6, Fattoria San Lorenzo',
  //   frTitle: 'B6, Fattoria San Lorenzo',
  //   jaTitle: 'B6、ファットリア・サン・ロレンツォ',
  //   zhTitle: '源自Fattoria San Lorenzo酒庄的B6葡萄酒',
  //   itAlternativeTitle: null,
  //   enAlternativeTitle: null,
  //   frAlternativeTitle: null,
  //   jaAlternativeTitle: null,
  //   zhAlternativeTitle: null,
  //   image: null,
  //   itDescription: 'Gucci Osteria presenta B6, dalla cantina Fattoria San Lorenzo di Montecarotto, Ancona, una delle cantine più importanti nella produzione di vino naturale in Italia. B6 nasce dagli esperimenti di Natalino Crognaletti, proprietario della tenuta e mente creativa di un processo che vede la combinazione di sei diverse annate (2014-2015-2016-2017-2018-2019) del suo vino Campo delle Oche, prodotto con uva Verdicchio.\n\nGrazie alle sue antiche tecniche di viticoltura naturale, la cantina Fattoria San Lorenzo produce dal 1995 alcune delle più affascinanti espressioni di Verdicchio della regione. Il B6 incarna la ricerca personale del fondatore di cogliere le molteplici sfumature organolettiche delle varie annate e di raggiungere un equilibrio difficile da ottenere con una sola annata. Il risultato è un vino unico, innovativo e complesso, con note minerali di pietra focaia e incenso, leggeri sentori floreali che giocano con fresche note verdi, completati da un tocco di acidità che apre il palato.',
  //   enDescription: 'Gucci Osteria presents B6 from Fattoria San Lorenzo winery of Montecarotto, Ancona, one of the most important producers of natural wine in Italy. B6 was born from the experiments of Natalino Crognaletti, owner of the estate and creative mind behind a process that involves combining six different vintages: 2014-2015-2016-2017-2018-2019 from his Campo delle Oche wine, made from Verdicchio grapes.\n\nHeralding age-old, natural viniculture techniques, Fattoria San Lorenzo has been producing some of the region\'s most fascinating expressions of Verdicchio since 1995. B6 embodies the founder’s personal quest to capture the many organoleptic nuances of the various years and to achieve a balance unlikely offered by a single vintage. The result is a unique, innovative and complex wine with mineral notes of flint and incense, light floral hints that play with fresh green notes, rounded off with a touch of acidity that opens up the palate.',
  //   frDescription: 'Gucci Osteria présente le B6 du vignoble Fattoria San Lorenzo de Montecarotto, Ancona, l’un des plus importants producteurs de vin naturel d’Italie. Le B6 est né des expériences de Natalino Crognaletti, propriétaire du domaine et créateur d’un processus qui consiste à combiner six millésimes différents : 2014-2015-2016-2017-2018-2019 de son vin Campo delle Oche, fabriqué à partir du cépage Verdicchio.\n\nFière de ses techniques de viticulture naturelles ancestrales, la Fattoria San Lorenzo produit certaines des expressions les plus fascinantes de Verdicchio depuis 1995. Le B6 incarne la quête personnelle du fondateur : capturer les nombreuses nuances organoleptiques des différentes années afin de parvenir à l’équilibre improbable offert par un seul millésime. Il en résulte un vin unique, innovant et complexe avec des notes minérales de silex et d’encens, des notes florales légères qui jouent avec des notes végétales fraîches, apportant une rondeur en bouche sur une touche d’acidité qui ouvre le palais.',
  //   jaDescription: 'グッチ オステリアから、イタリアにおける自然派ワインの最も有力な生産者のひとつであるファットリア・サン・ロレンツォの「B6」を紹介します。マルケ州アンコーナ県モンテカロットに拠点を置く同ワイナリーのオーナー、ナタリーノ・クロニャレッティ氏の実験から生まれたワイン「B6」は、ヴェルディッキオ種のブドウを使ったワインである「カンポ デッレ オケ」の2014、2015、2016、2017、2018、2019年という6種類の異なるヴィンテージをブレンドした、彼の創造力の賜物です。\n\nファットリア・サン・ロレンツォは昔ながらのビオディナミ農法により、1995年からヴェルディッキオ種を使った魅力的なワインを生産しています。B6には、さまざまなヴィンテージが持つ感覚を刺激するニュアンスを捉え、単一のヴィンテージでは表現できない調和を図るというオーナーの個人的な探求心を体現しています。その結果から生まれたこのワインは、革新的で複雑な独自の味わいに仕上がっており、火打石やインセンスなどのミネラル感や、軽いフローラルの香り、フレッシュグリーンのノートが感じられ、まろやかな酸味が口の中に広がります。',
  //   zhDescription: 'Gucci Osteria餐厅推出选自安科纳蒙特卡罗托市Fattoria San Lorenzo酒庄的B6葡萄酒，该酒庄是意大利久负盛名的天然葡萄酒生产商。酒庄主人Natalino Crognaletti在重重试验中研发B6葡萄酒在，以创意巧思将2014-2015-2016-2017-2018-2019不同年份的六款Campo delle Oche葡萄酒巧妙混合，采用维蒂奇诺葡萄酿制，入口香醇。\n\n自1995年以来，Fattoria San Lorenzo酒庄采用古老的天然葡萄栽培技术，生产当地最为香甜诱人的维蒂奇诺葡萄。B6葡萄酒承载着庄主的个人期望，捕捉不同年份红酒之间带来的细微感官差异，实现单一葡萄酒无法带来的平衡口感。这款口味独特且富含新意的混合葡萄酒油然而生，燧石和熏香的矿物质香气交织淡淡的花香与绿叶的清新，呈现微酸口感，引人陶醉。',
  //   enWpUrl: 'https://spirits.gucciosteria.com/gb/gbp/en/products/b6-fattoria-san-lorenzo-magnum-in-wooden-case.html',
  //   frWpUrl: 'https://spirits.gucciosteria.com/fr/eur/en/products/b6-fattoria-san-lorenzo-magnum-in-wooden-case.html'
  // },
]
