<template>
  <div v-click-outside="() => isVisible = false" class="lang-switcher">
    <button @click="isVisible = !isVisible" :class="{ clicked: isVisible }" class="current text-uppercase">
      <span>{{currentLanguage}}</span>
      <ArrowSvg />
    </button>
    <div class="languages" :class="{ visible: isVisible }">
      <router-link
        v-for="(language) in otherLanguages"
        :key="language"
        :to="{ name: $route.name, params: { ...$route.params, language } }"
        replace
        class="d-block text-uppercase mb-1"
        @click.native="onLocaleChange(language)"
      >
        <span>{{language}}</span>
      </router-link>
    </div>
  </div>
</template>

<script>
import { AVAILABLE_LOCALES } from '@/assets/data/constants'
import ArrowSvg from '@/assets/svg/arrow.svg'

export default {
  name: 'LangSwitcher',
  props: {},
  components: { ArrowSvg },
  data () {
    return { isVisible: false }
  },
  computed: {
    languages () {
      return AVAILABLE_LOCALES[this.$route.name] || AVAILABLE_LOCALES.default
    },
    currentLanguage () {
      return this.languages.find(language => language === this.$language.current)
    },
    otherLanguages () {
      return this.languages.filter(language => language !== this.currentLanguage)
    }
  },
  methods: {
    onLocaleChange (code) {
      this.isVisible = false
      this.trackChangeLocale(code)
    },
    trackChangeLocale (eventAction) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'generic_event',
          eventCategory: 'Click on Language',
          eventAction,
          eventLabel: ''
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-switcher {
  position: relative;
}

.current {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &.clicked {
    svg {
      transform: scaleY(-1);
    }
  }

  svg {
    width: 16px;
    height: 8px;
    margin-left: 8px;
    transition: transform 0.5s;
  }
}

.languages {
  position: absolute;
  top: 24px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: opacity 0.5s;

  &.visible {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  a {
    position: relative;
    color: currentColor;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      height: 1px;
      width: 10%;
      background-color: currentColor;
      opacity: 0;
      transition: opacity 0.2s, transform 0.2s;
    }

    &:hover::after {
      opacity: 1;
      width: 50%;
    }
  }
}
</style>
