import { ApolloClient, InMemoryCache } from '@apollo/client/core'
import { SchemaLink } from '@apollo/client/link/schema'
import { makeExecutableSchema } from 'graphql-tools/dist/makeExecutableSchema'
import { addMockFunctionsToSchema } from 'graphql-tools/dist/mock'
import VueApollo from 'vue-apollo'
import Vue from 'vue'
import axios from 'axios'

import find from 'lodash/find'
import isArray from 'lodash/isArray'
import isString from 'lodash/isString'
import isPlainObject from 'lodash/isPlainObject'
import cloneDeep from 'lodash/cloneDeep'
import keys from 'lodash/keys'

import shopifyClient from './ShopifyClient'

Vue.use(VueApollo)

const walk = (item, fn, parent) => {
  if (!parent) {
    item = fn(item, null, null)
  }

  if (isPlainObject(item) && !isString(item)) {
    const keyArr = keys(item)
    for (let i = 0; i < keyArr.length; i++) {
      const k = keyArr[i]
      item[k] = fn(item[k], item, k)
      walk(item[k], fn, item)
    }
  } else if (isArray(item)) {
    for (let i = 0; i < item.length; i++) {
      item[i] = fn(item[i], item, i)
      walk(item[i], fn, item)
    }
  }

  return item
}

// export default new VueApollo({ defaultClient: offlineClient })
export default class CMSOffline {
  /**
   *
   * @param catalog
   * @param schema
   */
  constructor (catalog, schema) {
    this.catalog = catalog
    this.schema = schema
    this.data = {}
    this.initialized = false
    this.fetchCache = {}
    this.completeDataCache = {}
  }

  /**
   *
   * @param item
   * @returns {*}
   */
  clone (item) {
    const ret = cloneDeep(item)
    // const resolvedEls = []
    return walk(ret, (el) => {
      // object is array
      if (isArray(el) && el.length > 0 && isPlainObject(el[0])) {
        // add ___toBeResolved to array values
        return el.map(e => {
          if (e) {
            return { ___toBeResolved: true, ...e }
          }
          return null
        })
      } else if (isString(el) && el.indexOf('_reference/') === 0) {
        // object is a string with _reference, it is an unresolved reference
        const split = el.split('/')
        return {
          __typename: split[1],
          id: split[2]
        }
      }
      return el
    })
  }

  /**
   *
   * @param el
   * @returns {boolean}
   */
  isPotentialRootType (el) {
    return el && isPlainObject(el) && el.__typename && el.id && el.__typename !== 'MediaFile' && el.__typename !== 'User'
  }

  /**
   *
   * @param el
   * @returns {boolean|*}
   */
  getRootTypeDefinition (el) {
    if (this.isPotentialRootType(el)) {
      return find(this.catalog.lists, { type: el.__typename })
    }
    return false
  }

  /**
   *
   * @param data
   * @param depth
   * @param maxDepth
   * @returns {Promise<*>}
   */
  async tryFillIncompleteData (data, depth = 0, maxDepth = 2) {
    if (depth < maxDepth) {
      // collect potential incomplete data
      const potentialIncompleteData = []
      walk(data, (item, parent, key) => {
        const list = this.getRootTypeDefinition(item)
        if (item !== data && list) {
          potentialIncompleteData.push({ item, parent, key, list })
        }
        return item
      })

      for (let i = 0; i < potentialIncompleteData.length; i++) {
        const candidate = potentialIncompleteData[i]
        const newList = await this.fetchRawData(candidate.list)
        // console.log('fetched list')
        const newEl = find(newList, { id: candidate.item.id })
        if (newEl) {
          candidate.parent[candidate.key] = cloneDeep(newEl)
          await this.tryFillIncompleteData(candidate.parent[candidate.key], depth + 1, maxDepth)
        }
      }
    }

    return data
  }

  /**
   *
   * @param list
   * @param depth
   * @param maxDepth
   * @returns {Promise<unknown[]>}
   */
  async fetchData (list, depth = 0, maxDepth = 3) {
    const data = await this.fetchRawData(list, depth, maxDepth)
    if (!this.completeDataCache[list.type]) {
      // console.log('ok')
      this.completeDataCache[list.type] = []
      for (let i = 0; i < data.length; i++) {
        this.completeDataCache[list.type].push(this.tryFillIncompleteData(cloneDeep(data[i])))
      }
    }
    return Promise.all(this.completeDataCache[list.type])
  }

  /**
   *
   * @param list
   * @param rawDepth
   * @param maxRawDepth
   * @returns {Promise<*>}
   */
  async fetchRawData (list, rawDepth = 0, maxRawDepth = 3) {
    if (!this.fetchCache[list.type]) {
      this.fetchCache[list.type] = new Promise((resolve, reject) => {
        axios.get(`${list.data}?hash=${process.env.GIT_COMMIT_HASH}`).then(({ data }) => {
          resolve(data)
          return data
        }).catch(e => reject(e))
      })
    }
    return this.fetchCache[list.type]
  }

  /**
   *
   * @param list
   * @returns {function(*, {slug?: *, id?: *, status: *}, {cache: *, mockQueries: *}, *): Promise<*|null>}
   */
  getResolver (list) {
    return async (root, { slug, id, status }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) this.data[list.type] = await this.fetchData(list)

      let data
      if (id) {
        // console.log('ID PARAM is set, resolving')
        data = this.clone(this.data[list.type].find((el) => el.id === id))
      } else if (slug) {
        // console.log('SLUG PARAM is set, resolving')
        data = this.clone(this.data[list.type].find((el) => el._slug === slug))
      }
      // console.log(data)
      if (data) {
        // cache.writeData({ data })
        return data
      }
      return null
    }
  }

  /**
   *
   * @param list
   * @returns {function(*, {sortOn: *, descending?: *, limit: *, cursor: *, status: *, filter: *}, {cache: *, mockQueries: *}, *): {cursor: null, totalCount: *, items: *}}
   */
  listResolver (list) {
    return async (root, { sortOn, descending = true, limit, cursor, status, filter }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) this.data[list.type] = await this.fetchData(list)
      const items = this.clone(this.data[list.type])
      const data = {
        items,
        cursor: null,
        totalCount: items.length
      }

      // cache.writeData({ data })

      // console.log('list resolver resolved')
      return data
    }
  }

  /**
   *
   * @param list
   * @returns {function(*=, {slug: *, id: *, status: *}, {cache: *, mockQueries: *}, *=): Promise<*|*|null>}
   */
  typeResolver (list) {
    return async (root, { slug, id, status }, { cache, mockQueries }, info) => {
      if (!this.data[list.type]) this.data[list.type] = await this.fetchData(list)
      // console.log('type resolver')
      const resolvedWithGet = await mockQueries[`Get${list.type}`](root, { slug, id, status }, { cache, mockQueries }, info)
      if (resolvedWithGet) {
        return resolvedWithGet
      } else {
        if (isArray(root[info.fieldName])) {
          // we are part of an array
          // console.log('field is array')

          const ret = root[info.fieldName].find(e => e ? e.___toBeResolved === true : false)
          if (ret) {
            // console.log('array found __toBeResolved')
            ret.___toBeResolved = false
            return ret
          }
          // console.error('array __toBeResolved NOT FOUND')
          return root[info.fieldName]
        } else if (root[info.fieldName] && root[info.fieldName].__typename && root[info.fieldName].id) {
          // console.log('WE ARE AN OBJECT, PROBABLY ALREADY RESOLVED')
          // return root[info.fieldName]
          return mockQueries[`Get${root[info.fieldName].__typename}`](root, { slug: null, id: root[info.fieldName].id, status }, { cache, mockQueries }, info)
        } else {
          console.warn(`Cannot resolve ${list.type} with id: ${id} or slug: ${slug}`)
          return null
          // throw new Error('Can\'t resolve')
        }
      }
    }
  }

  /**
   *
   */
  getProvider () {
    const mockQueries = {}
    const mockTypes = {}

    this.catalog.lists.forEach(l => {
      mockQueries[`Get${l.type}`] = this.getResolver(l)
      mockQueries[`List${l.type}`] = this.listResolver(l)
      mockTypes[l.type] = this.typeResolver(l)
    })

    const mocks = {
      // scalars
      RepeaterInstance (root, args, context, info) {
        return {}
      },
      // JSON (root, args, context, info) {
      //   return {}
      // },
      // URL (root, args, context, info) {
      //   return 'http://www.ciao.com'
      // },
      // Queries
      Query (root, args, context, info) {
        return mockQueries
      },
      // types
      ...mockTypes
    }

    const schema = makeExecutableSchema({ typeDefs: this.schema })
    addMockFunctionsToSchema({ schema, mocks })

    const cache = new InMemoryCache()

    const offlineClient = new ApolloClient({
      cache,
      link: new SchemaLink({ schema, context: { cache, mockQueries } })
    })

    return new VueApollo({
      defaultClient: offlineClient,
      clients: {
        contentClient: offlineClient,
        shopifyClient
      }
    })
  }
}
