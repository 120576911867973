import HeroImage from '@/assets/img/giardino25/hero.jpg'
import Image1 from '@/assets/img/giardino25/1.jpg'
import Image2 from '@/assets/img/giardino25/2.jpg'
import ImageGallery1 from '@/assets/img/giardino25/gallery/1.jpg'
import ImageGallery2 from '@/assets/img/giardino25/gallery/2.jpg'
import ImageGallery3 from '@/assets/img/giardino25/gallery/3.jpg'
import ImageGallery4 from '@/assets/img/giardino25/gallery/4.jpg'

export default {
  id: 'Giardino ',
  floatingCta: {
    enLabel: 'Discover our menu',
    itLabel: 'Scopri il menu',
    frLabel: 'Découvrez notre menù',
    jaLabel: 'メニューを見る',
    zhLabel: '探索我们的菜单',
    externalUrl: {
      en: 'https://www.gucciosteria.com/en/florence/giardino/menu',
      it: 'https://www.gucciosteria.com/it/florence/giardino/menu'
    }
  },
  sections: [
    {
      name: 'FullscreenImageSection',
      image: HeroImage,
      changeHeaderColor: true,
      bgImageFixed: true,
      enTitle: 'Gucci Giardino ',
      itTitle: undefined,
      frTitle: undefined,
      jaTitle: 'グッチ ジャルディーノ ',
      zhTitle: undefined,
      enCaption: 'Welcome to Gucci Giardino , a meeting point for all in the heart of Florence',
      itCaption: 'Benvenuti presso Gucci Giardino , un punto d\'incontro per tutti nel cuore di Firenze',
      frCaption: 'Bienvenue au Gucci Giardino , un endroit de rencontre pour tous au cœur de Florence',
      jaCaption: 'フィレンツェの中心ですべての人が集う場所、Gucci Giardino へようこそ',
      zhCaption: '欢迎莅临位于佛罗伦萨市中心的Gucci Giardino ，在此享受难忘的美好时光',
      cta: {
        enLabel: 'Discover more',
        itLabel: 'Scopri di più',
        frLabel: 'Découvrir plus',
        jaLabel: 'さらに見る',
        zhLabel: '查看更多',
        externalUrl: {
          en: 'https://www.gucciosteria.com/en/florence/giardino',
          it: 'https://www.gucciosteria.com/it/florence/giardino'
        }
      }
    },
    {
      name: 'ParagraphSection',
      enText: 'Located in Florence’s Piazza della Signoria, Gucci Giardino  is the House’s all-day café and cocktail bar. Taking inspiration from a historical florist, the previous tenant of one of the spaces in the square, the venue is characterized by harmonious contrasts, playing with the codes of the House and its Florentine surroundings while drawing from the colors, scents, and exuberance of a flower shop.',
      itText: 'Situato in Piazza della Signoria a Firenze, Gucci Giardino  è il caffè e cocktail bar della Maison aperto tutto il giorno. Ispirato a uno storico fioraio che un tempo occupava uno degli spazi della piazza, il locale è caratterizzato da contrasti armoniosi, che giocano con i codici della Maison fondendosi con l\'atmosfera fiorentina e ispirandosi ai colori, ai profumi e all\'esuberanza dei fioristi.',
      frText: 'Situé sur la Piazza della Signoria à Florence, Gucci Giardino  est le café et le bar à cocktails de la Maison. Inspiré du fleuriste historique qui occupait autrefois une partie des lieux, le café est défini par des contrastes harmonieux. Les codes de la Maison se mêlent à l’atmosphère florentine et s’inspirent des couleurs, des odeurs et de l’exubérance des fleuristes.',
      jaText: 'フィレンツェのシニョーリア広場に位置するGucci Giardino は、メゾンのオールデイ カフェ＆カクテルバーです。かつて同じ地にあった歴史あるフローリストにインスピレーションを得たこのスペースは、不思議な感覚と調和の取れたコントラストが特徴で、グッチのコードとフィレンツェの街の雰囲気を遊び心たっぷりに取り入れながら、色彩と香り、活気に満ちあふれています。',
      zhText: 'Gucci Giardino 位于佛罗伦萨领主广场（Piazza della Signoria），全天候提供咖啡和鸡尾酒饮品。该餐厅的灵感源自一家历史悠久的花店（曾经亦开设于领主广场），将品牌别具代表性的设计元素和佛罗伦萨的周边环境，与花店的色彩、芬芳和活力和谐融合。'
    },
    {
      name: 'FullscreenImageSection',
      image: Image1,
      changeHeaderColor: true
    },
    {
      name: 'ParagraphSection',
      enText: 'Gucci Giardino  is helmed by the Umbria-born Martina Bonci, whose passion for mixology has earned her numerous awards including Best Bartender under 35 by Food&Wine Italia 2023, and n.39 in Top 500 Bars 2023. Her signature line-up of balanced, colorful cocktails includes Mémoire di Negroni and Espresso Martina, characterized by creative inventiveness and high-quality ingredients, reflecting the core values of the House. Martina infuses the same level of dedication into her non-alcoholic cocktails, guided by her desire to offer a consistently imaginative experience across the entire drinks offering.',
      itText: 'Gucci Giardino  è gestito dall\'umbra Martina Bonci, la cui passione per la mixology le è valsa numerosi riconoscimenti, tra cui quello di Miglior Bartender under 35 di Food&Wine Italia 2023 e quello di n.39 nella Top 500 Bar 2023. La sua linea di cocktail equilibrati e colorati comprende Mémoire di Negroni e Espresso Martina, caratterizzati da inventiva creativa e ingredienti di alta qualità, che riflettono i valori fondamentali della Maison. Martina infonde lo stesso livello di dedizione nei suoi cocktail analcolici, guidata dal desiderio di offrire un\'esperienza straordinaria attraverso la sua selezione di bevande.',
      frText: 'Le Gucci Giardino  est dirigé par Martina Bonci, originaire d\'Ombrie, dont la passion pour la mixologie lui a valu de nombreuses récompenses, notamment celle de meilleure barmaid de moins de 35 ans par Food&Wine Italia 2023, et la 39e place dans le Top 500 Bars 2023. Sa sélection signature de cocktails équilibrés et colorés comprend le Mémoire di Negroni et l\'Espresso Martina et est définie par une créativité hors pair ainsi que des ingrédients d’une qualité exceptionnelle, à l’image des valeurs maîtresses de la Maison. Tout aussi impliquée dans ses cocktails sans alcool, Martina est animée par une envie de proposer une expérience hors du commun aux clients à travers sa sélection de boissons.',
      jaText: 'Gucci Giardino は、ウンブリア生まれのマルティーナ・ボンチが指揮を執り、彼女のミクソロジーへの情熱により、Food&Wine Italia 2023の35歳以下のベストバーテンダー賞、Top 500 Bars 2023の39位など、数々の賞を受賞しています。「メモワール・ディ・ネグローニ」や「エスプレッソ・マルティーナ」など、彼女のシグネチャーカクテルは、色彩豊かでバランスがよくクリエイティブで、グッチの世界が映し出されているかのようです。すべてのドリンクで想像力に富んだ体験を提供したいという彼女の思いから、ノンアルコールカクテルの開発にも取り組んでいます。',
      zhText: 'Gucci Giardino 由出生于温布利亚的Martina Bonci主理，她对调酒充满热忱并屡获殊荣，曾被2023年意大利《Food&Wine》杂志评选成为“35岁以下最佳调酒师”，以及荣获2023年500强酒吧第39名。她所创作的口感均衡、色彩缤纷的招牌鸡尾酒系列包括“Mémoire di Negroni”和“Espresso Martina”，融汇创意巧思与优质成分，彰显品牌的核心价值理念。Martina将同样的专注精神亦注入到无酒精鸡尾酒中，她希望为所有饮品提供能够不断激发创意巧思的体验。'
    },
    {
      name: 'TextAndImageSection',
      image: Image2,
      changeHeaderColor: true
    },
    {
      name: 'ParagraphSection',
      enText: 'Gucci Giardino ’s all-day menu sees the highest quality local ingredients employed in dishes that explore the Italian culinary tradition in a modern key, and are also available as part of a 2-course business lunch. Breakfast presents a varied and international selection, rounded out by Afternoon Tea, while Fridays are characterized by the Giardino  Aperitivo, offering a signature cocktail and a dish. A particular homage is paid to Italian coffee culture through the careful curation of special blends, which can be enjoyed in the form of an espresso at the bar or at a more leisurely pace in one of the space’s intimate seating areas.',
      itText: 'Il menu di Gucci Giardino , disponibile tutto il giorno, prevede l\'utilizzo di ingredienti locali di altissima qualità per creare piatti che esplorano la tradizione culinaria italiana in chiave moderna; è disponibile anche un pranzo di lavoro a 2 portate. La colazione presenta una selezione varia e internazionale, completata dal tè pomeridiano, mentre il venerdì è caratterizzato dall\'Aperitivo Giardino , che comprende un cocktail originale e un piatto. Un particolare omaggio è reso alla cultura del caffè italiano con miscele speciali accuratamente selezionate: formato espresso da gustare direttamente al bancone o caffè lungo da assaporare in una delle aree più intime del locale.',
      frText: 'Le menu, servi en continu, du Gucci Giardino  se compose d\'ingrédients locaux de la plus haute qualité sublimés dans des plats qui explorent la tradition culinaire italienne avec une touche de modernité. Il est également disponible sous la forme d\'un déjeuner d\'affaires deux services. Le petit-déjeuner présente une sélection variée et internationale. L\'établissement propose également un thé à déguster pendant l\'après-midi, tandis que le vendredi est caractérisé par le Giardino  Aperitivo, où un cocktail signature et un plat sont proposés. La culture du café italien sera mise à l’honneur avec les assemblages minutieux de spécialité sélectionnés  : format espresso à déguster directement au comptoir ou à savourer installé dans l’un des espaces plus intimes du lieu.',
      jaText: 'Gucci Giardino のオールデイ メニューでは、最高品質の地元食材を使用した、イタリア料理の伝統を現代風にアレンジした料理を提供し、2コースのビジネスランチとしてもご利用いただけます。バラエティーに富んだインターナショナル・セレクションの朝食、アフタヌーンティー、そして金曜日には、シグネチャーなカクテルと一品料理をお楽しみいただけるGiardino  アペリティーボをご用意しています。イタリアのカフェ文化へのオマージュとして、厳選したスペシャルブレンドのエスプレッソをバー、または着席エリアでゆっくりと味わっていただけます。',
      zhText: 'Gucci Giardino 的全日菜单采用本地优质食材精心制作，以现代风格探索意大利烹饪传统，亦作为两道餐点的商务午餐提供。除了国际化的现代早餐和下午茶之外，周五则为您特别献上Giardino 餐前酒，同时提供招牌鸡尾酒和一道菜肴。此外，精心调制的特调咖啡旨在向意大利咖啡文化致意。您可以前往酒吧或私人休息区，悉心品鉴意式浓缩咖啡。'
    },
    {
      name: 'FullscreenGallerySection',
      changeHeaderColor: true,
      slides: [
        { image: ImageGallery1 },
        { image: ImageGallery2 },
        { image: ImageGallery3 },
        { image: ImageGallery4 }
      ]
    }
  ]
}
