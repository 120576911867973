<template>
  <header
    :class="{
      light,
      'modal-open': $root.workingHourOpen || $root.menuOpen,
      'gucci-app': $root.isApp
    }"
  >
    <v-fade-transition>
      <div v-show="uiVisible" class="header-column">
        <v-fade-transition>
          <button
            v-show="ctaMenuVisible"
            @click="toggleMenu()"
            :class="{ clicked: $root.menuOpen }"
            type="button"
            class="cta-menu"
          >
            {{$gettext('menuLabel')}}
          </button>
        </v-fade-transition>
        <button
          @click="toggleStoreLocator()"
          :class="{
            clicked: $root.workingHourOpen,
            'menu-open': $root.menuOpen
          }"
          type="button"
          class="cta-store"
        >
          <StoreSvg />
        </button>
        <v-fade-transition>
          <router-link v-if="backTo" v-show="giardino25UiVisible" :to="backTo" class="cta-back">
            <BackSvg />
            <span>{{$gettext('back')}}</span>
          </router-link>
        </v-fade-transition>
      </div>
    </v-fade-transition>
    <router-link
      :to="{ name: 'Home' }"
      class="logo-container"
      @click.native="onLogoClick()">
      <v-fade-transition mode="out-in">
        <LogoVisionsJapanSvg v-if="logoVisionsVisible" />
        <LogoSvg v-else-if="logoVisible" />
      </v-fade-transition>
    </router-link>
    <div class="header-column">
      <portal-target name="room-audio" transition="v-fade-transition" slim />
      <v-fade-transition>
        <button
          v-show="uiVisible && ctaCartCtaVisible"
          @click="openCart()"
          type="button"
          class="cta-shop"
          :class="{ 'menu-open': $root.menuOpen }"
        >
          <ShopBagSvg />
        </button>
      </v-fade-transition>
      <v-fade-transition>
        <LangSwitcher v-show="uiVisible || ctaLangSwitcherVisible" />
      </v-fade-transition>
    </div>

    <portal to="working-hour">
      <Modal v-if="$root.workingHourOpen" @close="toggleStoreLocator()" :footer="$vuetify.breakpoint.smAndDown" :gradient="false">
        <template>
          <div class="modal-header">
            <h1 class="modal-title">{{$gettext('storeLocatorModalTitle')}}</h1>
            <p class="modal-subtitle">{{$gettext('storeLocatorModalText')}}</p>
          </div>
          <NavigationRooms v-if="$vuetify.breakpoint.mdAndUp" :rooms="$root.settings.menu.primaryItems" :currentIndex.sync="currentIndex" :relative="$vuetify.breakpoint.mdAndUp" :bottom="$vuetify.breakpoint.smAndDown" :buttons="true" class="my-16" />
            <div class="text-center text-md-left d-md-flex items-md-center justify-md-center store-info px-md-12">
              <v-fade-transition mode="out-in">
                <MarkdownBlock
                  :key="$root.settings.menu.primaryItems[currentIndex].workingHours[`${$language.current}Text`]"
                  :text="$root.settings.menu.primaryItems[currentIndex].workingHours[`${$language.current}Text`]"
                  class="working-hours-text mb-10 mb-md-0 mr-md-12"
                />
              </v-fade-transition>
              <v-fade-transition mode="out-in">
                <MarkdownBlock
                  :key="$root.settings.menu.primaryItems[currentIndex].address[`${$language.current}Text`]"
                  :text="$root.settings.menu.primaryItems[currentIndex].address[`${$language.current}Text`]"
                  class="address-hours-text ml-md-12"
                />
              </v-fade-transition>
            </div>
        </template>
        <template slot="footer">
          <NavigationRooms :rooms="$root.settings.menu.primaryItems" :currentIndex.sync="currentIndex" :relative="$vuetify.breakpoint.mdAndUp" :bottom="$vuetify.breakpoint.smAndDown" :buttons="true" :itemsClickable="false" />
        </template>
      </Modal>
    </portal>
  </header>
</template>

<script>
import Giardino25Content from '@/assets/data/giardino'
import Modal from '@/organisms/Modal.vue'
import NavigationRooms from '@/molecules/NavigationRooms.vue'
import LangSwitcher from '@/atoms/LangSwitcher.vue'

import LogoSvg from '@/assets/svg/gucci_palazzo_logo.svg'
import LogoVisionsJapanSvg from '@/assets/svg/gucci_visions_logo.svg'
import StoreSvg from '@/assets/svg/store.svg'
import BackSvg from '@/assets/svg/arrow.svg'
import ShopBagSvg from '@/assets/svg/shopbag.svg'

import MarkdownBlock from '@monogrid/vue-lib/lib/components/MarkdownBlock'

export default {
  name: 'Header',
  components: {
    Modal,
    LogoSvg,
    LogoVisionsJapanSvg,
    StoreSvg,
    BackSvg,
    ShopBagSvg,
    LangSwitcher,
    NavigationRooms,
    MarkdownBlock
  },
  data () {
    return {
      currentIndex: 0,
      giardino25UiVisible: true,
      forceLightColor: false
    }
  },
  beforeDestroy () {
    this.tl && this.tl.kill()
  },
  mounted () {
    this.$events.on('update-light-color', (value) => { this.forceLightColor = value })
    this.$events.on('menu-animation-start', () => { this.giardino25UiVisible = false })
    this.$events.on('menu-animation-end', () => { this.giardino25UiVisible = true })
    this.$events.on('open-working-hours-modal', ({ index = 0 }) => {
      this.$root.workingHourOpen = true
      this.currentIndex = index
    })
  },
  computed: {
    backTo () {
      if (this.$route.name === 'Giardino25Product' || this.$route.name === 'Giardino25ProductBooking') {
        if (this.$root.prevRoute && this.$root.prevRoute.name === 'Room') {
          return {
            name: this.$root.prevRoute.name,
            params: this.$root.prevRoute.params
          }
        }
        return { name: 'Giardino25Products' }
      } else if (this.$route.name === 'Giardino25Products' || this.$route.name === 'Giardino25Menu') {
        return { name: 'Giardino25' }
      }
      return undefined
    },
    light () {
      if (this.forceLightColor && !this.$root.menuOpen) return true
      if (
        this.$route.name?.includes('Giardino25') ||
        this.$route.name === 'RegistrationLanding' ||
        this.$route.name === 'VisionsJapan' ||
        this.$route.name === 'Editorial'
      ) return false
      return (this.$route.name !== 'Home' && !this.$root.mapOpen) &&
        !((this.$root.menuOpen || this.$root.audioguideOpen) ||
        (this.$vuetify.breakpoint.smAndDown && (this.$root.workingHourOpen || this.$route.params.contentSlug)))
    },
    uiVisible () {
      if (
        this.$root.galleryFullscreen ||
        this.$route.name === 'Giardino25PrivacyPolicy' ||
        this.$route.name === 'RegistrationLanding' ||
        this.$route.name === 'VisionsJapan'
      ) return false
      return this.$vuetify.breakpoint.smAndDown
        ? (!this.$root.workingHourOpen && !this.$route.params.contentSlug && !this.$root.mapOpen && !this.$root.audioguideOpen)
        : (!this.$root.mapOpen && !this.$root.audioguideOpen)
    },
    ctaMenuVisible () {
      return this.$route.name !== 'RegistrationLanding' && this.$route.name !== 'VisionsJapan'
    },
    ctaCartCtaVisible () {
      if (this.shopifyBuyAlwaysAvailable) return true
      return Giardino25Content.settings.availableLocales.includes(this.$language.current) &&
      (
        this.$route.name === 'Giardino25' ||
        this.$route.name === 'Giardino25Products' ||
        this.$route.name === 'Giardino25Product'
      )
    },
    ctaLangSwitcherVisible () {
      return this.$route.name === 'RegistrationLanding' || this.$route.name === 'VisionsJapan'
    },
    shopifyBuyAlwaysAvailable () {
      return Giardino25Content.settings.productAlwaysAvailable.find((id) => (this.$route.params.productId === id))
    },
    logoVisionsVisible () {
      return this.$route.name === 'VisionsJapan'
    },
    logoVisible () {
      return !this.logoVisionsVisible
    }
  },
  methods: {
    toggleMenu () {
      if (this.$root.workingHourOpen) this.$root.workingHourOpen = false
      this.$root.menuOpen = !this.$root.menuOpen
    },
    toggleStoreLocator () {
      if (this.$root.menuOpen) this.$root.menuOpen = false
      this.$root.workingHourOpen = !this.$root.workingHourOpen
      if (this.$root.workingHourOpen) this.trackevent('Click on opening hours', this.$route.params.roomSlug, '')
    },
    openCart () {
      this.$root.shopifyCartOpen = true
    },
    trackevent (eventCategory, eventAction, eventLabel) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'generic_event',
          eventCategory,
          eventAction,
          eventLabel
        })
      }
    },
    onLogoClick () {
      this.trackevent('Click on logo', '', '')
    }
  }
}
</script>

<style lang="scss" scoped>
header {
  pointer-events: none;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: $z-header;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 40px;
  height: $header-height;
  color: $c-black;
  transition: background-color 0.15s ease-out, color 0.25s ease-out;

  @include breakpoint ('sm-and-down') {
    // height: $header-height / 1.25;
    padding: 0 16px;

    &.gucci-app {
      top: 34px;
    }
  }

  .editorial-page &,
  .giardino-25 & {
    background-color: $c-white;
    color: $c-black;
  }

  &.light {
    background-color: transparent;
    color: $c-white;
  }

  a {
    color: currentColor;
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.header-column {
  display: flex;
  align-items: center;
  gap: 48px;

  @include breakpoint ('sm-and-down') {
    gap: 24px;
  }
}

.cta-menu {
  position: relative;
  text-transform: uppercase;

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    height: 1px;
    width: 10%;
    background-color: currentColor;
    opacity: 0;
    transition: all 0.2s;
  }

  .no-mobile &:hover::after,
  &.clicked::after {
    opacity: 1;
    width: 50%;
    background-color: currentColor;
  }
}

.cta-store {
  position: relative;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  transition: background-color 0.2s;

  .no-mobile &:hover,
  &.clicked {
    color: $c-black;
    background-color: $c-white;
  }

  circle,
  path {
    transition: all 0.25s ease-out;
  }
}

.cta-back {
  position: relative;
  display: flex;
  align-items: center;
  color: currentColor;
  transition: color 0.25s ease-out;

  @include breakpoint ('sm-and-down') {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }

  span {
    display: inline-block;
    font-family: 'Nitti Grotesk Normal';
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }

  svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    transform: rotate(90deg);
  }
}

.logo-container {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: color 0.25s ease-out;
  width: 275px;
  height: 20px;

  @include breakpoint ('sm-and-down') {
    width: 130px;
  }

  svg {
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}

.cta-shop {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  color: currentColor;
  transition: color 0.25s ease-out;
}

::v-deep {
  .modal-content {
    @include breakpoint ('sm-and-down') {
      padding: $header-height 40px $footer-height;
    }

    &.gucci-app {
      padding: $header-height * 1.5 40px $footer-height;
    }
  }

  .modal-header {
    margin-top: 50px;
  }

  .modal-title {
    font-family: 'Nitti Grotesk Normal';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: 0.02em;

    @include breakpoint ('xs-only') {
      margin: 20px 0;
    }
  }

  .modal-subtitle {
    font-family: 'Nitti Grotesk Normal';
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }
}

.store-info {
  margin: 100px auto;
  letter-spacing: 0.05em;
  color: $c-black;

  @include breakpoint ('md-only') {
    margin: 12px auto;
  }

  @include breakpoint ('xs-only') {
    width: 100%;
    margin: 50px auto 100px;
  }

  strong {
    font-family: 'Nitti Grotesk Bold';
    font-size: 20px;
    line-height: 26px;
  }

  p {
    font-family: 'Nitti Grotesk Normal';
    font-size: 20px;
    line-height: 21px;
  }

  .working-hours-text,
  .address-hours-text {
    min-width: 30%;

    @include breakpoint ('sm-and-down') {
      width: auto;
    }

    &::v-deep a {
      color: $c-black;
    }
  }
}
</style>
