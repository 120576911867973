<template>
  <button v-on="$listeners" v-bind="$attrs">
    <CloseSvg />
  </button>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'

export default {
  name: 'ButtonClose',
  components: { CloseSvg }
}
</script>

<style lang="scss" scoped>
button {
  outline: none;
  width: 16px;
  height: 16px;
  transition: transform 0.25s ease-out !important;

  .no-mobile &:hover {
    transform: rotate(180deg);
  }

  svg {
    width: 100%;
    height: 100%;
    fill: $c-black !important;
    stroke: $c-black !important;
    stroke-width: 1px;
  }
}
</style>
