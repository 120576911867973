<template>
  <component v-bind="$attrs" v-on="$listeners" :is="component" :class="{ mobile }" class="cta-text">
    <div v-if="$slots.icon" class="icon">
      <slot name="icon" />
    </div>
    <slot name="text" />
  </component>
</template>

<script>
export default {
  name: 'CtaText',
  props: {
    component: { type: String, default: 'button' }
  },
  computed: {
    mobile () {
      return this.$root.platform.mobile
    }
  }
}
</script>

<style lang="scss" scoped>
.cta-text {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  font-family: 'Nitti Grotesk Bold';
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.05em;
  color: $c-black;
  opacity: 0.4;
  transition: opacity 0.5s;

  .icon {
    width: 22px;
    height: 18px;
    position: absolute;
    top: 50%;
    left: -33px;
    transform: translateY(-50%);
    opacity: 0;
    margin-right: 12px;
    transition: opacity 0.5s;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    height: 1px;
    width: 10%;
    background-color: $c-black;
    opacity: 0;
    transition: all 0.5s;
  }

  &.mobile,
  &:hover,
  &.active {
    opacity: 1;

    .icon {
      opacity: 1;
      visibility: visible;
    }

    &::after {
      opacity: 1;
      transform: translateX(-50%) scaleX(5);
    }
  }
}
</style>
