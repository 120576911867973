const CURRENCIES = {
  EUR: '€',
  GBP: '£',
  USD: '$'
}

export function gid (id, entity = 'Product') {
  return `gid://shopify/${entity}/${id}`
}

export function formatPrice ({ price } = {}, { minVariantPrice, maxVariantPrice, quantity = 1 } = {}) {
  if (!price) return
  if (parseFloat(price.amount) === 0) return 'FREE'
  const prefix = (minVariantPrice?.amount !== maxVariantPrice?.amount) ? 'From ' : ''
  return `${prefix} ${(parseFloat(price.amount) * quantity).toFixed(2)} ${CURRENCIES[price.currencyCode] || price.currencyCode}`
}

export function formatTotalPrice ({ totalPrice } = {}) {
  if (!totalPrice) return
  return `${CURRENCIES[totalPrice.currencyCode] || totalPrice.currencyCode} ${(parseFloat(totalPrice.amount)).toFixed(2)}`
}
