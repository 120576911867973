import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform.js'

import router from './plugins/router.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Preloader from './preloader'
import PortalVue from 'portal-vue'
import onlineApolloProvider from './services/CMS'
import CMSOffline from './services/CMSOffline'
import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'

import settings from '@/assets/data/settings'

// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)
// usage: https://github.com/imcvampire/vue-axios
Vue.use(VueAxios, axios)
// usage: https://github.com/LinusBorg/portal-vue
Vue.use(PortalVue)

//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
// router.afterEach((to, from) => {
// preloaderInstance.visible = false
// })

const USE_OFFLINE = true

//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  apolloProvider: USE_OFFLINE ? new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider() : onlineApolloProvider,
  // apollo: {
  //   MainRooms: {
  //     query: MainRoomsQuery,
  //     variables () {
  //       return {
  //         status: 'PUBLISHED'
  //       }
  //     },
  //     async result () {
  //       await this.$nextTick()
  //       if (!this.mainRooms.length) {
  //         for (let i = 0; i < this.MainRooms.items.length; i++) {
  //           const room = { ...this.MainRooms.items[i] }
  //           if (!room.hide) this.mainRooms.push(room)
  //         }
  //         this.mainRooms.sort((a, b) => a.order - b.order)
  //       }
  //     }
  //   }
  // },
  data () {
    return {
      USE_OFFLINE,
      settings,
      menuOpen: false,
      mapOpen: false,
      audioguideOpen: false,
      workingHourOpen: false,
      shopifyCartOpen: false,
      galleryFullscreen: false,
      checkoutID: localStorage.getItem('shopifyCheckout') || null,
      checkoutLoading: false,
      isApp: false,
      preloader: preloaderInstance,
      process: {
        env: process.env
      },
      prevRoute: null
    }
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  },
  watch: {
    $route (to, from) {
      this.prevRoute = from
    }
  }
})
