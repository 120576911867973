var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navigation-container",class:{ relative: _vm.relative }},[_c('ul',{staticClass:"d-none d-md-flex align-md-center justify-md-space-around"},_vm._l((_vm.rooms),function(room,index){return _c('li',{key:room.id},[_c('CtaText',{class:{ active: index === _vm.currentIndex },attrs:{"component":_vm.buttons ? 'button' : room.to ? 'router-link' : 'a',"to":(!_vm.buttons && room.to)
          ? {
            name: room.to.name,
            params: room.to.params
          }
          : undefined,"href":(!_vm.buttons && room.externalUrl)
          ? (room.externalUrl[_vm.$language.current] || room.externalUrl.en)
          : undefined,"target":(!_vm.buttons && room.externalUrl) ? '_blank' : undefined},nativeOn:{"click":function($event){return _vm.onRoomClick(room, index)},"mouseenter":function($event){_vm.updateOnHover ? _vm.$emit('update:currentIndex', index) : null},"mouseleave":function($event){_vm.updateOnHover ? _vm.$emit('update:currentIndex', null) : null}}},[_c('template',{slot:"text"},[_c('span',[_vm._v(_vm._s(room[_vm.$language.current + 'Title']))])])],2)],1)}),0),_vm._v(" "),_c('div',{staticClass:"d-flex align-center justify-space-between d-md-none fill-height"},[_c('button',{staticClass:"controls prev",class:{ visible: _vm.showPrev },attrs:{"type":"button"},on:{"click":function($event){return _vm.onListPrev()}}},[_c('ArrowSvg')],1),_vm._v(" "),_c('v-fade-transition',{attrs:{"mode":"out-in"}},[(_vm.currentRoom && _vm.currentIndex !== null)?_c(_vm.buttons ? 'button' : _vm.currentRoom.to ? 'router-link' : 'a',{key:_vm.currentIndex,tag:"component",attrs:{"to":_vm.currentRoom.to ? { name: _vm.currentRoom.to.name, params: Object.assign({}, _vm.currentRoom.to.params) } : undefined,"href":_vm.currentRoom.externalUrl ? _vm.currentRoom.externalUrl[_vm.$language.current] || _vm.currentRoom.externalUrl.en : undefined,"target":_vm.currentRoom.externalUrl ? '_blank' : undefined}},[_c('h2',[_vm._v(_vm._s(_vm.currentRoom[_vm.$language.current + 'Title']))])]):_vm._e(),_vm._v("compone\n    ")],1),_vm._v(" "),_c('button',{staticClass:"controls next",class:{ visible: _vm.showNext },attrs:{"type":"button"},on:{"click":function($event){return _vm.onListNext()}}},[_c('ArrowSvg')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }