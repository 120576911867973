import { primaryItems, secondaryItems } from './menu'

export default {
  menu: {
    primaryItems,
    secondaryItems
  },
  termsAndConditionsUrl: 'https://www.gucci.com/it/it/st/privacy-landing',
  itPrivacyPolicyUrl: 'https://www.gucci.com/documents/legal/privacy/it.pdf',
  enPrivacyPolicyUrl: 'https://www.gucci.com/documents/legal/privacy/en.pdf',
  frPrivacyPolicyUrl: 'https://www.gucci.com/documents/legal/privacy/fr.pdf',
  jaPrivacyPolicyUrl: null,
  zhPrivacyPolicyUrl: null,
  instagramUrl: 'https://www.instagram.com/gucci/',
  facebookUrl: 'https://www.facebook.com/GUCCI/'
}
