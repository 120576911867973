import Vue from 'vue'
import VueRouter from 'vue-router'

import routes from '../routes'

// docs: https://router.vuejs.org/en/
Vue.use(VueRouter)

export default new VueRouter({
  // mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
