<template>
  <div v-show="visible" class="rotate-device">
    <div class="content">
      <v-container fluid fill-height>
        <v-row class="height-100 pa-0" align="center" justify="center">
          <v-col align="center">
            <IconRotate />
            <p class="mt-3 uppercase">{{$gettext('rotateDevice')}}</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import Viewport from '@monogrid/vue-lib/lib/mixins/Viewport'
import IconRotate from '@/assets/svg/rotate.svg'

export default {
  name: 'RotateDevice',
  mixins: [Viewport],
  components: {
    IconRotate
  },
  computed: {
    visible () {
      return this.$vuetify.breakpoint.smAndDown && this.viewPort.width > this.viewPort.height
    }
  }
}
</script>

<style lang="scss" scoped>
.rotate-device {
  position: fixed;
  width: 100vw;
  height: 100%;
  z-index: 1000;
  background-color: $c-white;
  color: $c-black;
  padding: 5vh 12vw;
  text-align: center;
  text-transform: uppercase;

  svg {
    width: 48px;
    height: 48px;

    path {
      fill: $c-black;
    }
  }
}

.content {
  // background-color: $c-white;
  width: 100%;
  height: 100%;
}
</style>
