import data from './landing'
import products from './products'

export default {
  data,
  products,
  settings: {
    availableLocales: ['it'],
    osteriaShopURL: 'https://spirits.gucciosteria.com/',
    productAlwaysAvailable: [
      '8423370359123',
      '8473348145491',
      '8473348538707',
      '8473348702547'
    ],
    cart: {
      emptyCartProductID: '7152823795892'
    },
    productFilters: {
      categories: [
        { id: 0, label: 'all' },
        { id: 1, label: 'experience' },
        { id: 2, label: 'product' }
      ]
    }
  }
}
