<template>
  <footer>
    <nav>
      <ul>
        <li><a @click="trackMenuItemClick($gettext('termsAndConditionsLabel'))" :href="$root.settings.termsAndConditionsUrl" target="_blank" rel="noopener noreferrer">{{$gettext('termsAndConditionsLabel')}}</a></li>
        <li class=""><a @click="trackMenuItemClick($gettext('cookiePolicyLabel'))" href="/cookie-policy/index.html" target="_blank" rel="noopener noreferrer">{{$gettext('cookiePolicyLabel')}}</a></li>
        <li class=""><a @click="trackMenuItemClick($gettext('privacyPolicyLabel'))" :href="$root.settings[$language.current + 'PrivacyPolicyUrl']" target="_blank" rel="noopener noreferrer">{{$gettext('privacyPolicyLabel')}}</a></li>
      </ul>
      <ul>
        <li><a @click="trackMenuItemClick('Instagram')" :href="$root.settings.instagramUrl" target="_blank" rel="noopener noreferrer"><InstagramSvg /></a></li>
        <li class=""><a @click="trackMenuItemClick('Facebook')" :href="$root.settings.facebookUrl" target="_blank" rel="noopener noreferrer"><FacebookSvg /></a></li>
      </ul>
    </nav>
  </footer>
</template>

<script>
import InstagramSvg from '@/assets/svg/social/instagram.svg'
import FacebookSvg from '@/assets/svg/social/facebook.svg'

export default {
  name: 'Menu',
  components: {
    InstagramSvg,
    FacebookSvg
  },
  methods: {
    trackMenuItemClick (item) {
      let eventAction = 'Click on Menu footer cta'
      let eventLabel = ''

      if (item.room && item.room.enTitle) eventLabel = item.room.enTitle
      else if (item.enTitle) eventLabel = item.enTitle
      else if (item.labels) eventLabel = item.labels.find(label => label.lang === 'en').text
      else {
        eventAction = 'Click on Menu footer cta'
        eventLabel = item
      }

      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'generic_event',
          eventCategory: 'Menu tendina',
          eventAction,
          eventLabel
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  position: relative;
  padding: 40px;

  @include breakpoint ('sm-and-down') {
    padding: 20px;
  }
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul {
  display: flex;
  align-items: center;

  &:first-of-type {
    gap: 40px;

    @include breakpoint ('sm-and-down') {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }

  &:last-of-type {
    gap: 24px;

    @include breakpoint ('sm-and-down') {
      gap: 12px;
      align-items: flex-end;
    }
  }
}

a {
  display: flex;
  align-items: center;
  font-family: 'Nitti Grotesk Normal';
  font-size: 16px;
  line-height: 18px;
  color: $c-black;
  // opacity: 0.6;
  transition: opacity 0.4s;

  @include breakpoint ('sm-and-down') {
    opacity: 1;
    font-size: 14px;
    line-height: 15px;
  }

  &:hover {
    opacity: 1;
  }

  svg {
    width: 1rem;
    height: 1rem;
  }
}
</style>
