<template>
  <nav :class="{ relative }" class="navigation-container">
    <ul class="d-none d-md-flex align-md-center justify-md-space-around">
      <li v-for="(room, index) in rooms" :key="room.id">
        <CtaText
          :component="buttons ? 'button' : room.to ? 'router-link' : 'a'"
          :to="(!buttons && room.to)
            ? {
              name: room.to.name,
              params: room.to.params
            }
            : undefined"
          :href="(!buttons && room.externalUrl)
            ? (room.externalUrl[$language.current] || room.externalUrl.en)
            : undefined
          "
          :target="(!buttons && room.externalUrl) ? '_blank' : undefined"
          :class="{ active: index === currentIndex }"
          @click.native="onRoomClick(room, index)"
          @mouseenter.native="updateOnHover ? $emit('update:currentIndex', index) : null"
          @mouseleave.native="updateOnHover ? $emit('update:currentIndex', null) : null"
        >
        <template slot="text"><span>{{room[$language.current + 'Title']}}</span></template>
        </CtaText>
      </li>
    </ul>
    <div class="d-flex align-center justify-space-between d-md-none fill-height">
      <button :class="{ visible: showPrev }" @click="onListPrev()" type="button" class="controls prev"><ArrowSvg /></button>
      <v-fade-transition mode="out-in">
        <component
          v-if="currentRoom && currentIndex !== null"
          :is="buttons ? 'button' : currentRoom.to ? 'router-link' : 'a'"
          :to="currentRoom.to ? { name: currentRoom.to.name, params: { ...currentRoom.to.params } } : undefined"
          :href="currentRoom.externalUrl ? currentRoom.externalUrl[$language.current] || currentRoom.externalUrl.en : undefined"
          :target="currentRoom.externalUrl ? '_blank' : undefined"
          :key="currentIndex"
        >
          <h2>{{currentRoom[$language.current + 'Title']}}</h2>
        </component>compone
      </v-fade-transition>
      <button :class="{ visible: showNext }" @click="onListNext()" type="button" class="controls next"><ArrowSvg /></button>
    </div>
  </nav>
</template>

<script>
import CtaText from '@/atoms/CtaText.vue'
import ArrowSvg from '@/assets/svg/circle_arrow.svg'

export default {
  name: 'NavigationRooms',
  props: {
    rooms: { type: Array },
    currentIndex: { type: Number, default: 0 },
    relative: { type: Boolean, required: false, default: false },
    bottom: { type: Boolean, required: false, default: false },
    buttons: { type: Boolean, required: false, default: false },
    updateOnHover: { type: Boolean, required: false, default: false },
    loop: { type: Boolean, required: false, default: true },
    itemsClickable: { type: Boolean, required: false, default: false }
  },
  components: { CtaText, ArrowSvg },
  async mounted () {
    await this.$nextTick()
    this.$emit('update:currentRoomSlug', this.currentRoom && this.currentRoom._slug)
  },
  watch: {
    async currentIndex () {
      await this.$nextTick()
      this.$emit('update:currentRoomSlug', this.currentRoom && this.currentRoom._slug)
    }
  },
  computed: {
    currentRoom () {
      return this.currentIndex >= 0 && this.rooms && this.rooms.length && this.rooms[this.currentIndex]
    },
    showPrev () {
      return this.loop ? true : this.currentIndex > 0
    },
    showNext () {
      if (!this.rooms) return
      return this.loop ? true : this.currentIndex < this.rooms.length - 1
    }
  },
  methods: {
    onRoomClick (room, index) {
      this.$emit('update:currentIndex', index)
      this.$emit('room-click', room)
    },
    onListPrev () {
      const newIndex = (this.currentIndex > 0) ? this.currentIndex - 1 : this.rooms.length - 1
      this.$emit('update:currentIndex', newIndex)
    },
    onListNext () {
      const newIndex = (this.currentIndex < this.rooms.length - 1) ? this.currentIndex + 1 : 0
      this.$emit('update:currentIndex', newIndex)
    }
  }
}
</script>

<style lang="scss" scoped>
.navigation-container {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  z-index: 2;
  padding: 0 40px;

  @include breakpoint ('sm-and-down') {
    padding: 0 16px;
    height: 100%;
  }

  &.relative {
    position: relative;
    top: 0;
    transform: translateY(0);
  }

  span {
    font-family: 'Nitti Grotesk Normal';
    font-size: 24px;
    line-height: 1;
    text-align: center;

    @include breakpoint ('md-and-down') {
      font-size: 20px;
      line-height: 26px;
    }
  }

  a {
    color: $c-black;

    @include breakpoint ('sm-and-down') {
      display: block;
      max-width: 70%;
      margin: auto;
    }
  }

  h2 {
    font-family: 'Nitti Grotesk Normal';
    font-size: 22px;
    line-height: 1;
    letter-spacing: 0.05em;
    text-align: center;
  }

  .controls {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;

    &.visible {
      opacity: 1;
      pointer-events: all;
    }

    &.prev {
      transform: rotate(0deg);
    }

    &.next {
      transform: rotate(180deg);
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      color: $c-black;
    }
  }
}
</style>
