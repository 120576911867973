/*
const shopifyConfig = {
  domain: 'checkout-florence.gucciosteria.com/',
  storefrontAccessToken: '11ee656b0d1f74e570a54b282ddd527e'
}
*/

import { ApolloClient, InMemoryCache } from '@apollo/client/core'

const shopifyConfig = {
  domain: 'checkout-florence.gucciosteria.com',
  storefrontAccessToken: '3601e50ca60baf4b401bfb9fa65c0c3b'
}

const shopifyClient = new ApolloClient({
  connectToDevTools: process.env.NODE_ENV !== 'production',
  cache: new InMemoryCache(),
  uri: `https://${shopifyConfig.domain}/api/2021-07/graphql.json`,
  headers: {
    'X-Shopify-Storefront-Access-Token': shopifyConfig.storefrontAccessToken
  }
  // link: from([
  //   onError(errorHandler),
  //   requestLink,
  //   new BatchHttpLink({
  //     // batchInterval: 100,
  //     uri: `https://${shopifyConfig.domain}/api/2021-04/graphql.json`,
  //     headers: {
  //       'X-Shopify-Storefront-Access-Token': shopifyConfig.storefrontAccessToken
  //     }
  //   })
  // ]),
})

export default shopifyClient
