<template>
  <v-app
    v-show="!$root.platform.prerenderer"
    id="app"
    class="app"
    :class="{
      'giardino-25': $route.name && $route.name.includes('Giardino25'),
      'editorial-page': $route.name === 'Editorial',
      'audioguide-page': $route.name === 'RegistrationLanding',
      'visions-japan-page': $route.name === 'VisionsJapan'
    }"
  >
    <Loading />
    <portal-target name="working-hour" transition="v-fade-transition" slim />
    <portal-target name="modal" transition="v-fade-transition" slim />
    <Cart />
    <Menu />
    <v-fade-transition mode="out-in">
      <Header v-if="headerVisible" />
    </v-fade-transition>
    <v-fade-transition mode="out-in">
      <router-view
        :key="$route.name"
        :introSeen.sync="introSeen"
      />
    </v-fade-transition>
    <VersionInfo
      v-if="!$root.USE_OFFLINE"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
    />
    <RotateDevice />
  </v-app>
</template>

<script>

import Loading from '@/atoms/Loading.vue'

import Cart from '@/molecules/Cart.vue'

import Menu from '@/organisms/Menu.vue'
import Header from '@/organisms/Header.vue'

import VersionInfo from '@monogrid/vue-lib/lib/components/VersionInfo'
import RotateDevice from './organisms/RotateDevice.vue'

export default {
  name: 'App',
  components: {
    Loading,
    Cart,
    Menu,
    Header,
    VersionInfo,
    RotateDevice
  },
  data () {
    return {
      introSeen: false
    }
  },
  metaInfo () {
    return {
      htmlAttrs: {
        lang: this.$language.current
      }
    }
  },
  mounted () {
    if (this.$root.platform.ios) {
      document.addEventListener('touchmove', function (event) {
        event = event.originalEvent || event
        if (event.scale !== 1) {
          event.preventDefault()
        }
      }, { passive: false })
    }

    this.$root.preloader.visible = false
  },
  computed: {
    headerVisible () {
      return this.$route.name === 'Home' ? this.introSeen : true
    }
  },
  watch: {
    $route (to, from) {
      if (to.query.app) {
        this.$root.isApp = true
      }
    }
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Nitti Grotesk Light';
  font-style: normal;
  font-weight: 300;
  font-display: block;
  src:
    url('assets/fonts/NittiGroteskLightTT.woff2') format('woff2'),
    url('assets/fonts/NittiGroteskLightTT.woff') format('woff');
}

@font-face {
  font-family: 'Nitti Grotesk Normal';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url('assets/fonts/NittiGroteskNormalTT.woff2') format('woff2'),
    url('assets/fonts/NittiGroteskNormalTT.woff') format('woff');
}

@font-face {
  font-family: 'Nitti Grotesk Medium';
  font-style: normal;
  font-weight: 500;
  font-display: block;
  src:
    url('assets/fonts/NittiGroteskMediumTT.woff2') format('woff2'),
    url('assets/fonts/NittiGroteskMediumTT.woff') format('woff');
}

@font-face {
  font-family: 'Nitti Grotesk Bold';
  font-style: normal;
  font-weight: 700;
  font-display: block;
  src:
    url('assets/fonts/NittiGroteskBoldTT.woff2') format('woff2'),
    url('assets/fonts/NittiGroteskBoldTT.woff') format('woff');
}

@font-face {
  font-family: 'Mailart Rubberstamp';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src:
    url('assets/fonts/MailartRubberstampRegular.woff2') format('woff2'),
    url('assets/fonts/MailartRubberstampRegular.woff') format('woff');
}

* {
  font-weight: normal;

  &:not(input) {
    user-select: none;
  }
}

html {
  overflow: auto !important;
}

.app {
  color: $c-black;
  background: $c-white;
}

a {
  text-decoration: none;
  pointer-events: all;

  &:active,
  &:focus,
  &:visited {
    color: currentColor;
  }
}

button {
  outline: none;
  pointer-events: all;
}

ul {
  list-style: none !important;
  padding-left: 0 !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
