<template>
  <div v-click-outside="() => { !fullscreen && closeModal() }" :class="{ fullscreen, 'gucci-app': $root.isApp }" class="modal">
    <div :class="{ 'd-md-none': !gradient }" class="gradient-top"></div>
    <div :class="{ 'd-md-none': !gradient, 'd-none': $root.workingHourOpen || $root.mapOpen }" class="gradient-bottom"></div>
    <slot name="close">
      <ButtonClose @click.native="closeModal()" class="close" />
    </slot>
    <div class="modal-content">
      <slot />
    </div>
    <div v-if="footer" class="modal-footer">
      <div class="gradient-bottom"></div>
      <div class="line"></div>
      <div :class="{ 'd-md-none': !gradient }" class="footer-content">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonClose from '@/atoms/ButtonClose.vue'

export default {
  name: 'Modal',
  props: {
    footer: { type: Boolean, required: false, default: false },
    fullscreen: { type: Boolean, required: false, default: false },
    gradient: { type: Boolean, required: false, default: false }
  },
  components: { ButtonClose },
  methods: {
    closeModal () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: $header-height;
  left: 40px;
  right: 40px;
  z-index: $z-modal;
  width: calc(100% - 80px);
  height: calc(100% - 240px);
  background-color: $c-white;
  color: $c-black;
  border: 1px solid $c-black;
  overflow: hidden;

  @include breakpoint ('sm-and-down') {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &.fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  &.gucci-app {
    .gradient-top {
      background: linear-gradient(180deg, $c-white 30%, rgba($c-white, 0%) 100%);
    }

    .close {
      top: 74px;
    }
  }

  .gradient-top {
    pointer-events: none;
    user-select: none;
    position: fixed;
    z-index: inherit;
    width: 100%;
    height: $header-height * 1.5;
    top: 0;
    left: 0;
    background: linear-gradient(180deg, $c-white 0%, rgba(255, 166, 176, 0) 100%);
  }

  .gradient-bottom {
    pointer-events: none;
    user-select: none;
    position: absolute;
    z-index: inherit;
    width: 100%;
    height: $header-height;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: linear-gradient(0deg, $c-white 0%, rgba(255, 166, 176, 0) 100%);
  }

  .close {
    position: absolute;
    z-index: $z-close;
    top: 40px;
    right: 40px;
    width: 18px;
    height: 18px;
    color: currentColor;
    transition: transform 0.25s ease-out;

    @include breakpoint ('sm-and-down') {
      position: fixed;
      top: 30px;
      right: 16px;
    }

    &:hover {
      transform: rotate(180deg);
    }

    svg {
      width: 100% !important;
      height: 100% !important;
    }
  }

  /* hide scrollbar but allow scrolling */
  .modal-content {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .modal-content::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  .modal-content {
    height: 100%;
    width: 100%;
    max-width: 1550px;
    margin: 0 auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .modal-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: $footer-height;

    .gradient-bottom {
      pointer-events: none;
      user-select: none;
      position: absolute;
      width: 100%;
      height: $header-height * 2;
      bottom: 0;
      right: 0;
      z-index: 0;
      background: linear-gradient(180deg, rgba(255, 166, 176, 0) 0%, $c-white 55%);
    }

    .line {
      opacity: 0.3;
      width: calc(100% - 80px);
      height: 1px;
      margin: auto;
      background-color: $c-black;

      @include breakpoint ('sm-and-down') {
        width: calc(100% - 32px);
      }
    }
  }

  .footer-content {
    width: 100%;
    height: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
