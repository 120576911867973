import packageConfig from '../package.json'
import Platform from '@monogrid/js-utils/lib/Platform.js'
import availableLanguages from '@/assets/data/availableLanguages.json'

//
// only pages should be imported and used here
// http://bradfrost.com/blog/post/atomic-web-design/
//

let routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "page-home" */ './pages/HomePage')
  },
  {
    name: 'RegistrationLanding',
    path: '/virtual-tour-registration-landing-page',
    component: () => import(/* webpackChunkName: "page-registration-landing" */ './pages/RegistrationLandingPage.vue')
  },
  {
    name: 'VisionsJapan',
    path: '/gucci-visions-japan',
    component: () => import(/* webpackChunkName: "page-registration-landing" */ './pages/VisionsJapanPage.vue')
  },
  {
    path: '/gucci-giardino-25',
    component: () => import(/* webpackChunkName: "page-editorial-giardino-layout" */ './pages/Giardino25LayoutPage'),
    alias: ['/:language/giardino25', '/:language/giardino-25'],
    children: [
      {
        name: 'Giardino25',
        path: '',
        component: () => import(/* webpackChunkName: "page-editorial-giardino" */ './pages/Giardino25Page')
      },
      {
        name: 'Giardino25Menu',
        path: 'menu',
        component: () => import(/* webpackChunkName: "page-editorial-giardino-menu" */ './pages/Giardino25MenuPage')
      },
      {
        path: 'products',
        component: () => import(/* webpackChunkName: "page-editorial-giardino-products-layout" */ './pages/Giardino25ProductsLayoutPage'),
        children: [
          {
            name: 'Giardino25Products',
            path: '',
            component: () => import(/* webpackChunkName: "page-editorial-giardino-products" */ './pages/Giardino25ProductsPage')
          },
          {
            name: 'Giardino25Product',
            path: ':productId',
            props: true,
            component: () => import(/* webpackChunkName: "page-editorial-giardino-product" */ './pages/Giardino25ProductPage')
          },
          {
            name: 'Giardino25ProductBooking',
            path: 'booking/:productId',
            props: (route) => ({ ...route.params, isLocalProduct: true }),
            component: () => import(/* webpackChunkName: "page-editorial-giardino-product-booking" */ './pages/Giardino25ProductPage')
          }
        ]
      },
      {
        name: 'Giardino25PrivacyPolicy',
        path: 'privacy-policy',
        component: () => import(/* webpackChunkName: "page-editorial-menu" */ './pages/Giardino25PrivacyPolicyPage')
      }
    ]
  },
  {
    name: 'Editorial',
    path: '/editorial-page/:pageSlug',
    props: true,
    component: () => import(/* webpackChunkName: "page-editorial-content" */ './pages/EditorialPage')
  },
  {
    path: '/360/:floorSlug/:roomSlug/:contentSlug?',
    name: 'Room',
    props: true,
    component: () => import(/* webpackChunkName: "page-room" */ './pages/RoomPage')
  }
]

//
// System routes
//
if (!packageConfig.multilanguage || packageConfig.multilanguage === 'false') {
  // no multilanguage
  routes = routes.concat([
    {
      // matches /anyroute (if not found) and redirects it to /
      path: '*',
      redirect: '/'
    }
  ])
} else {
  //
  // multilanguage system
  // don't touch this :)
  //

  // create a regex capturing group like en|it|es|ru
  // based on availableLanguages
  let possibleLanguages = ''
  Object.keys(availableLanguages).forEach((lang) => {
    possibleLanguages += lang + '|'
  })
  possibleLanguages = possibleLanguages.substring(0, possibleLanguages.lastIndexOf('|'))

  // determine user browser language
  let userLanguage
  if (!Platform.prerenderer) {
    userLanguage = (window.navigator.languages ? window.navigator.languages[0] : null) || window.navigator.userLanguage || window.navigator.language || 'en'
    // strip down '-US' part (as in 'en-US' or similar) if present
    userLanguage = userLanguage.split('-')[0]
  } else {
    userLanguage = 'en'
  }

  const getValidLanguage = () => {
    // if it exist, set user language as default
    if (availableLanguages[userLanguage]) {
      return userLanguage
    }
    // set first language as default
    return Object.keys(availableLanguages)[0]
  }

  routes.forEach(route => {
    const oldPath = route.path
    // modify routes to require a valid language before it
    route.path = `/:language(${possibleLanguages})${route.path}`

    if (Platform.prerenderer) {
      // create duplcate routes for pre-renderer with no language
      // it needs them in order to avoid redirection from / to /en (for example)

      // TODO: Deep clone of nested routes?
      const dup = {}
      Object.keys(route).forEach(key => {
        dup[key] = route[key]
      })
      dup.name = route.name + '-prerenderer'
      dup.path = oldPath
      routes.push(dup)
    }
  })
  // append utility routes for redirection
  routes = routes.concat([
    {
      // matches /validlanguage/invalidroute and redirects it to /validlanguage
      path: `/:language(${possibleLanguages})/*`,
      redirect: to => {
        return `/${getValidLanguage()}`
      }
    },
    {
      // matches /invalidlanguage(2 chars)/anyroute and redirects it to /validlanguage/anyroute
      path: '/:language(\\w{2,2}\\b)/*',
      redirect: to => {
        return `/${getValidLanguage()}/${to.params[0]}`
      }
    },
    {
      // matches /anyroute (if not found) and redirects it to /validlanguage/anyroute
      path: '*',
      redirect: to => {
        return `/${getValidLanguage()}${to.path}`
      }
    }
  ])
}

export default routes
